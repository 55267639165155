import { Pagination } from 'swiper';
import { SwiperConfig } from '../carousel/contacts';

const maxSlidesPerView = 3;

const tariffCarouselConfig: SwiperConfig = {
  loop: true,
  grabCursor: true,
  slidesPerView: maxSlidesPerView,
  breakpoints: {
    1200: {
      slidesPerView: 3,
    },
    720: {
      slidesPerView: 2,
    },
    320: {
      slidesPerView: 1,
    },
  },
  spaceBetween: 50,
  speed: 700,
  className: 'tarriffsSwiper',
  modules: [Pagination],
  pagination: { clickable: true },
};

export default tariffCarouselConfig;
