import * as yup from 'yup';
import { InferType } from 'yup';
import { requiredFieldMessage } from '../../../../utils/constants';

const schema = yup.object().shape({
  title: yup
    .string()
    .required(requiredFieldMessage)
    .matches(/^[а-яА-ЯёЁ0-9- ]+$/, 'Кирилица и цифры')
    .min(2, 'Название должно быть больше 2 символов'),
  code: yup
    .string()
    .required(requiredFieldMessage)
    .matches(/^[0-9]+$/, 'Только цифры'),
});

export type DistrictFormData = InferType<typeof schema>;

export default schema;
