import { Box, List } from '@mui/material';
import { ListItemProps, MyListItem } from './MyListItem';
import { FC } from 'react';

export type SideMenuProps = {
  listItems: ListItemProps[];
};

const SideMenu: FC<SideMenuProps> = ({ listItems }) => {
  return (
    <Box>
      <List>
        {listItems.map((listItem) => (
          <MyListItem
            key={listItem.text}
            text={listItem.text}
            link={listItem.link}
            color={listItem.color}
          />
        ))}
      </List>
    </Box>
  );
};

export default SideMenu;
