const ContactsSvg = () => (
  <svg className="nav-svg contacts-svg" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path
      data-name="Path 131"
      d="M10 6a2 2 0 1 0-2 2 2 2 0 0 0 2-2ZM8 7a1 1 0 1 1 1-1 1 1 0 0 1-1 1Zm7.5-1H15V3.5A3.5 3.5 0 0 0 11.5 0h-10a.5.5 0 0 0-.5.5v1.545H.5a.5.5 0 0 0 0 1H1V6H.5a.5.5 0 0 0 0 1H1v3H.5a.5.5 0 0 0 0 1H1v3H.5a.5.5 0 0 0 0 1H1v.5a.5.5 0 0 0 .5.5h10a3.5 3.5 0 0 0 3.5-3.5V10h.5a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5ZM15 9h-1a1 1 0 0 1 0-2h1Zm-1 3.5a2.5 2.5 0 0 1-2.5 2.5h-9a.5.5 0 0 0 0-1H2v-3h.5a.5.5 0 0 0 0-1H2V7h.5a.5.5 0 0 0 0-1H2V3.045h.545a.5.5 0 0 0 0-1H2V1h9.5A2.5 2.5 0 0 1 14 3.5V6a2 2 0 0 0 0 4ZM8.5 8h-1A3.5 3.5 0 0 0 4 11.5 1.5 1.5 0 0 0 5.5 13h5a1.5 1.5 0 0 0 1.5-1.5A3.5 3.5 0 0 0 8.5 8Zm2 4h-5a.5.5 0 0 1-.5-.5A2.5 2.5 0 0 1 7.5 9h1a2.5 2.5 0 0 1 2.5 2.5.5.5 0 0 1-.5.5Z"
    />
  </svg>
);

export default ContactsSvg;
