import { Typography } from '@mui/material';
import { FieldValues, Path, UseFormRegister } from 'react-hook-form';

import './index.scss';

type DateInputProps<DataT extends FieldValues> = {
  label: string;
  id: string;
  error: boolean;
  helperText?: string;
  register: UseFormRegister<DataT>;
  name: Path<DataT>;
};

const DateInput = <DataT extends FieldValues>({
  label,
  id,
  error,
  helperText,
  register,
  name,
}: DateInputProps<DataT>) => {
  const classes = !error ? 'date-picker' : 'date-picker error';
  return (
    <div>
      <div className="date-container">
        <label htmlFor={id}>
          <Typography
            component="p"
            variant="body1"
            sx={{ mb: '.5rem', color: `${error ? '#d32f2f;' : 'black'}` }}
          >
            {label}:
          </Typography>
        </label>
        <input className={classes} {...register(name)} id={id} />
        <span className="open-button">
          <button type="button">📅</button>
        </span>
      </div>
      {error && (
        <Typography
          component="p"
          variant="body1"
          sx={{ color: '#d32f2f', fontSize: '.75rem', ml: '1rem' }}
        >
          {helperText}
        </Typography>
      )}
    </div>
  );
};
export default DateInput;
