import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteTariff } from '../../../api/tariff/tariffApi';
import { TarifTypeAndId } from '../contracts.tariff';

const deleteTariffThunk = createAsyncThunk(
  'tariff/deleteTariffThunk',
  async ({ id, key }: TarifTypeAndId) => {
    await deleteTariff(id);
    return { id, key };
  }
);

export default deleteTariffThunk;
