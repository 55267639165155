import { Button, useTheme } from '@mui/material';
import { useNavigate } from 'react-router';
import MoreSvg from '../../svgIcons/MoreSvg';

const MoreBtn = ({ link }: { link: string }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(link);
  };

  const theme = useTheme();

  return (
    <Button variant="outlined" color="primary" onClick={handleClick}>
      <MoreSvg fill={theme.palette.primary.main} />
    </Button>
  );
};

export default MoreBtn;
