import { combineReducers, configureStore, PreloadedState } from '@reduxjs/toolkit';

import { apiSlice } from '../api/apiSlice/apiSlice';
import authStoreSlice from '../storeSlices/authSLice/authStoreSlice';
import newsStoreSlice from '../storeSlices/newsSlice/';
import tariffStoreSlice from '../storeSlices/tariffSlice/tariffStoreSlice';
import { RootState } from './store.contracts';

export const rootReducer = combineReducers({
  news: newsStoreSlice,
  tariffs: tariffStoreSlice,
  auth: authStoreSlice,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
    devTools: true,
  });

export default setupStore;
