import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Avatar, Box, Button, Container, Typography } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import schema, { AuthFormData } from './yupSchema';
import { getAuthFormDefaultValues } from './utils';
import { Input } from '../../components/customInputs/Input';
import Copyright from '../../components/copyright';
import { useAppDispatch } from '../../hooks';
import { useLocation, useNavigate } from 'react-router';
import { useLoginMutation } from '../../storeSlices/authSLice/authApiSlice/authApiSlice';
import { auth } from '../../storeSlices/authSLice/authStoreSlice';
import CustomCheckbox from '../../components/customInputs/Checkbox';
import { useCallback, useRef } from 'react';
import useEnqueueMsgWithData from '../../hooks/useEnqueueMsg/useEnqueueMsgWithData';
import Spinner from '../../components/spinner/Spinner';

const theme = createTheme();

export default function SignIn() {
  const [login, { isLoading }] = useLoginMutation();
  const formRef = useRef<HTMLFormElement>(null);

  const defaultValues = getAuthFormDefaultValues();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    control,
  } = useForm<AuthFormData>({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const appDispath = useAppDispatch();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  const signIn = useCallback(
    async ({ remember, ...data }: AuthFormData) => {
      const tokens = await login(data).unwrap();
      appDispath(auth({ tokens, remember }));
      navigate(from, { replace: true });
    },
    [appDispath, from, login, navigate]
  );

  const onSubmit = useEnqueueMsgWithData<AuthFormData>({
    fn: signIn,
    msg: 'Успешно залогинились',
  });

  const onInputKeyDown = () => {
    isValid && formRef.current?.requestSubmit();
  };

  return (
    <>
      {isLoading && <Spinner open={true} />}
      {!isLoading && (
        <ThemeProvider theme={theme}>
          <Container sx={{ paddingTop: '5rem' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Войти
              </Typography>
              <Box
                component="form"
                ref={formRef}
                noValidate
                onSubmit={handleSubmit(onSubmit)}
                sx={{ mt: 1, maxWidth: '550px' }}
              >
                <Box sx={{ mb: '1rem', mt: '2rem' }}>
                  <Input
                    {...register('login')}
                    label="Логин"
                    placeholder="Введите ваш логин..."
                    error={!!errors.login}
                    helperText={errors.login?.message}
                  />
                </Box>
                <Input
                  {...register('password')}
                  label="Пароль"
                  placeholder="Введите ваш пароль..."
                  error={!!errors.password}
                  helperText={errors.password?.message}
                  onKeyDown={onInputKeyDown}
                />
                <CustomCheckbox<AuthFormData>
                  label="Запомнить меня"
                  control={control}
                  name="remember"
                />
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                      mt: '2rem',
                      mb: '3rem',
                      maxWidth: '250px',
                      paddingY: '.7rem',
                    }}
                  >
                    Войти
                  </Button>
                </Box>
                {/*
             for the case if there would be a possibility to recover password
            <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Забыл пароль?
                  </Link>
                </Grid>
            </Grid>
              */}
                <Copyright marginTop={2} />
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      )}
    </>
  );
}
