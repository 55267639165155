import { green } from '@mui/material/colors';
import { SvgComponentProps } from './svgIconContracts';

const UserSvg = ({ fill = green[500] }: SvgComponentProps) => (
  <svg className="user-svg" viewBox="-1 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 0a6 6 0 1 1 0 12 6 6 0 0 1 0-12zM1 22.099a1 1 0 0 1-1-1V19a5 5 0 0 1 5-5h10a5 5 0 0 1 5 5v2.099a1 1 0 0 1-1 1H1z"
      fill={fill}
    />
  </svg>
);

export default UserSvg;
