import { SvgComponentProps } from './svgIconContracts';

const WirelessSvg = ({ stroke = '#000', width = '30px', height = '30px' }: SvgComponentProps) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    className="wireless-svg"
  >
    <path
      d="M16 74C-3 49 7 5 50 5c45 0 49 46 34 69"
      style={{
        fill: 'none',
        stroke: stroke,
        strokeWidth: 6,
      }}
    />
    <path
      d="M27 68c-16-20-5-50 23-50 27 0 37 29 23 50"
      style={{
        fill: 'none',
        stroke: stroke,
        strokeWidth: 5,
      }}
    />
    <path
      d="M36 63c-9-12-5-33 14-33s23 23 14 33"
      style={{
        fill: 'none',
        stroke: stroke,
        strokeWidth: 4,
      }}
    />
    <path
      d="m41 100 5-40s-6-1-6-9c0-6 4-10 10-10s10 4 10 10c0 7-5 9-5 9l5 40z"
      style={{
        fill: stroke,
      }}
    />
  </svg>
);
export default WirelessSvg;
