import {
  CreateTariffDto,
  ITariff,
  TariffTypeOption,
} from '../../../../storeSlices/tariffSlice/contracts.tariff';
import { TariffFormData } from './yupSchema';

export const getTariffFormDefaultValues = (tariff: ITariff | null | undefined) => {
  return tariff
    ? {
        title: tariff.title,
        type: tariff.type,
        cost: String(tariff.cost),
        speed: String(tariff.speed),
      }
    : { title: '', speed: '', cost: '', type: 'wired' as TariffTypeOption };
};

export const formatTariffFormData = (data: TariffFormData): CreateTariffDto => ({
  ...data,
  speed: +data.speed,
  cost: +data.cost,
});

export const fileFormats = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];
