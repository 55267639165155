import { Box, Container, Typography } from '@mui/material';

import Spinner from '../../components/spinner';
import { useParams } from 'react-router';
import { useAppSelector } from '../../hooks';
import formatDate from '../../utils/formatDate';

import { selectedIsNewsLoading, selectedOneNews } from '../../storeSlices/newsSlice';

const NewsPage = () => {
  const { id } = useParams();

  const news = useAppSelector(selectedOneNews(id as string));
  const isLoading = useAppSelector(selectedIsNewsLoading);
  return (
    <>
      {isLoading ? (
        <Spinner open={true} />
      ) : (
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: '3rem',
            paddingBottom: '5rem',
          }}
        >
          <Box
            sx={{
              maxWidth: '600px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography component="h1" variant="h3" textAlign="center" sx={{ mb: '3rem' }}>
              {news?.title}
            </Typography>
            <Box
              sx={{
                mb: '3rem',
              }}
            >
              <img src={news?.backgroundImage} alt="Новость" />
            </Box>
            <Typography component="h1" variant="h3" textAlign="center" sx={{ mb: '3rem' }}>
              {news?.content}
            </Typography>
            <OrderItem title="Дата создания" value={formatDate(news?.createdAt)} />
          </Box>
        </Container>
      )}
    </>
  );
};

type OrderItemProps = { title?: string; value?: string };

export const OrderItem = ({ title, value }: OrderItemProps) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <Typography component="p" variant="h5" sx={{ mr: '1rem' }}>
        {title}:
      </Typography>
      <Typography component="p" variant="h6">
        {value}
      </Typography>
    </Box>
  );
};

export default NewsPage;
