import { SvgComponentProps } from './svgIconContracts';

const ToArchiveSvg = ({
  fill = 'none',
  stroke = '#000',
  width = '30px',
  height = '30px',
}: SvgComponentProps) => (
  <svg
    width={width}
    height={height}
    className="to-archive-svg"
    xmlns="http://www.w3.org/2000/svg"
    fill={fill}
    viewBox="0 0 24 24"
  >
    <path
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M7 20H6a2 2 0 0 1-2-2V8h16v10a2 2 0 0 1-2 2h-1M6 4h12l2 4H4l2-4ZM12 14v6m0 0 2.5-2.5M12 20l-2.5-2.5"
    />
  </svg>
);
export default ToArchiveSvg;
