import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import computeColor from '../../utils/computeColor';

type CheckboxProps<FormData extends FieldValues> = {
  error?: boolean;
  control: Control<FormData, unknown>;
  name: Path<FormData>;
  label: string;
};

const CustomCheckbox = <FormData extends FieldValues>({
  error = false,
  control,
  name,
  label,
}: CheckboxProps<FormData>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: true }}
      render={({ field }) => (
        <FormControlLabel
          control={
            <Checkbox
              {...field}
              sx={{
                color: computeColor({ isError: error }),
              }}
            />
          }
          label={<Typography color={computeColor({ isError: error })}>{label}</Typography>}
        />
      )}
    />
  );
};

export default CustomCheckbox;
