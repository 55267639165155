import { blue } from '@mui/material/colors';
import { ListItemProps } from '../../../components/SideMenu/MyListItem';

export const adminkaSideMenuItems: ListItemProps[] = [
  { text: 'Новости', link: '/adminka/news' },
  {
    text: 'Тарифы',
    link: '/adminka/tariffs',
  },
  { text: 'Заказы', link: '/adminka/orders' },
  {
    text: 'Населенные пункты',
    link: '/adminka/districts',
  },
  {
    text: 'Главное меню',
    link: '/',
    color: blue[800],
  },
];
