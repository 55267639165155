import { toSelectOptions } from '../../../utils/toSelectOptions';
import toBase64 from '../../../utils/toBase64';
import { useCreateOrderMutation } from '../../../api/order/query/orderApiSlice';
import { useCallback, useMemo } from 'react';
import useEnqueueMsgWithData from '../../../hooks/useEnqueueMsg/useEnqueueMsgWithData';
import OrderFormCreatePresent from './OrderFormCreatePresent';
import { useParams } from 'react-router';
import { useAppSelector } from '../../../hooks';
import { selectedTariffs } from '../../../storeSlices/tariffSlice/tariffStoreSlice';
import { ITariff } from '../../../storeSlices/tariffSlice/contracts.tariff';
import { useGetDistrictsQuery } from '../../../api/district/query/districtApiSlice';
import { IDistrcit } from '../../../api/district/districtApi';
import { getOrderFormDefaultValues } from '../helpers/getOrderFormDefaultValues';
import { OrderFormData } from '../helpers/orderFormSchema';
import Spinner from '../../../components/spinner';

export default function OrderFormCreate() {
  const { tariffId } = useParams();
  const tariffs = useAppSelector(selectedTariffs);

  const tariffsOptions = useMemo(
    () => toSelectOptions<ITariff>({ arr: tariffs, key: 'title' }),
    [tariffs]
  );

  const { isLoading: isDistrictsLoading, data: districts } = useGetDistrictsQuery();
  const districtsOptions = useMemo(
    () => toSelectOptions<IDistrcit>({ arr: districts ?? [], key: 'title' }),
    [districts]
  );

  const tariffsDefaultOption = tariffId
    ? tariffsOptions.find((tariff) => tariff.value === tariffId)?.value
    : tariffsOptions[0].value;

  const defaultValues = getOrderFormDefaultValues({
    tariffId: tariffsDefaultOption ?? tariffsOptions[0].value,
    districtId: districtsOptions[0].value,
  });

  const [createOrder] = useCreateOrderMutation();

  const generateOrder = useCallback(
    async ({ aggreddToShareDate, ...createOrderDto }: OrderFormData) => {
      const mainPageHash = await toBase64(createOrderDto.passportMainPagePhoto[0]);
      const registrationHash = await toBase64(createOrderDto.passportMainPagePhoto[0]);

      await createOrder({
        ...createOrderDto,
        passportMainPagePhoto: mainPageHash,
        passportRegistrationPhoto: registrationHash,
        passportDate: createOrderDto?.passportDate.toISOString(),
        birthday: createOrderDto.birthday.toISOString(),
      }).unwrap();
    },
    [createOrder]
  );

  const onSubmit = useEnqueueMsgWithData<OrderFormData>({
    fn: generateOrder,
    msg: 'Заказ создан',
  });

  return (
    <>
      {isDistrictsLoading ? (
        <Spinner open={true} />
      ) : (
        <OrderFormCreatePresent
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          title="Сделать заказ"
          districtsOptions={districtsOptions}
          tariffsOptions={tariffsOptions}
        />
      )}
    </>
  );
}
