import { NavigationListItems } from './navigation/Navigation';
import { ContactsSvg, InternetSvg, SaleSvg, TVSvg, WalletSvg } from './svgIcons';

export const mainNavigationList: NavigationListItems = [
  { title: 'ИНТЕРНЕТ', children: <InternetSvg /> },
  { title: 'ЦИФРОВОЕ ТВ', children: <TVSvg />, classes: 'tv' },
  { title: 'АКЦИИ', children: <SaleSvg /> },
  { title: 'ОПЛАТА', children: <WalletSvg /> },
  { title: 'КОНТАКТЫ', children: <ContactsSvg /> },
];
