import { createTheme } from '@mui/material';

const theme = createTheme();

theme.typography.body1 = {
  fontSize: '1rem',
  fontWeight: 500,
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.1rem',
  },
};
export default theme;
