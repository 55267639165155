const TvHdSvg = () => (
  <svg
    className="feature-svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
  >
    <path d="M313.672 145.004h-18.876a7.603 7.603 0 0 0-7.604 7.604v12.265c0 4.2 3.404 7.604 7.604 7.604s7.604-3.404 7.604-7.604v-4.661h11.272c3.978 0 7.215 3.236 7.215 7.215v84.137c0 6.634-5.396 12.029-12.029 12.029H302.4v-71.039a7.605 7.605 0 0 0-15.208 0v78.643c0 4.2 3.404 7.604 7.604 7.604h14.061c15.018 0 27.237-12.219 27.237-27.237v-84.137c.001-12.364-10.058-22.423-22.422-22.423z" />
    <path d="M335.471 121.167h-12.674a7.603 7.603 0 0 0-7.604 7.604c0 4.2 3.404 7.604 7.604 7.604h12.674c5.103 0 9.255 4.152 9.255 9.255V271.2c0 8.951-7.283 16.234-16.234 16.234h-49.93v-151.06h10.152c4.2 0 7.604-3.404 7.604-7.604s-3.404-7.604-7.604-7.604h-17.756a7.603 7.603 0 0 0-7.604 7.604v166.265c0 4.2 3.404 7.604 7.604 7.604h57.534c17.337 0 31.442-14.105 31.442-31.442V145.628c-.001-13.487-10.974-24.461-24.463-24.461zM225.572 121.167h-23.839a7.603 7.603 0 0 0-7.604 7.604v63.61h-32.085v-63.61c0-4.2-3.404-7.604-7.604-7.604h-23.839a7.603 7.603 0 0 0-7.604 7.604v166.265c0 4.2 3.405 7.604 7.604 7.604h23.839c4.2 0 7.604-3.404 7.604-7.604v-63.61h32.085v63.61c0 4.2 3.404 7.604 7.604 7.604h23.839c4.2 0 7.604-3.404 7.604-7.604V128.771c0-4.2-3.404-7.604-7.604-7.604zm-7.604 166.264h-8.631v-63.61c0-4.2-3.404-7.604-7.604-7.604H154.44a7.603 7.603 0 0 0-7.604 7.604v63.61h-8.631V136.374h8.631v63.61c0 4.2 3.404 7.604 7.604 7.604h47.293c4.2 0 7.604-3.404 7.604-7.604v-63.61h8.631v151.057z" />
    <path d="M504.396 44.613H69.453c-4.2 0-7.604 3.404-7.604 7.604s3.404 7.604 7.604 7.604h427.34V73.51H15.208V52.217a7.605 7.605 0 0 0-15.208 0v319.37c0 4.2 3.404 7.604 7.604 7.604h178.563v43.589h-27.734a7.603 7.603 0 0 0-7.604 7.604v29.398c0 4.2 3.404 7.604 7.604 7.604h178.239c4.2 0 7.604-3.404 7.604-7.604v-29.398c0-4.2-3.404-7.604-7.604-7.604h-27.734v-43.589h195.458c4.2 0 7.604-3.404 7.604-7.604V52.217a7.605 7.605 0 0 0-7.604-7.604zM329.067 437.99v14.191h-163.03V437.99h163.03zm-127.692-15.209v-43.589h92.354v43.589h-92.354zm295.417-58.797H15.208v-13.689h481.584v13.689zm0-28.897H15.208V88.719h481.584v246.368z" />
  </svg>
);

export default TvHdSvg;
