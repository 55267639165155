import { NavigationListItems } from '../navigation/Navigation';

export const profileNavigationList: NavigationListItems = [
  { title: 'Профиль', link: '/profile/main' },
  {
    title: 'Счет',
    link: '/profile/account',
  },
  {
    title: 'Оплата',
    link: '/profile/payment',
  },
  { title: 'Доверительный платеж', link: '/profile/confidential-payment' },
  { title: 'Автоматическое списание', link: '/profile/auto-payment' },
];
