import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import './styles.scss';

import SwiperButtons from './SwiperButtons';
import { useResponsibleSlidesPerView } from '../../hooks';
import { SwiperConfig } from './contacts';

type WithCarouselProps = {
  config: SwiperConfig;
  Component: React.ElementType;
  isIncludeSwperButtons?: boolean;
  isResponsive?: boolean;
};

type ObjectWithId = { id: string };

type InnerWithCarouselProps<DataT> = {
  data: DataT;
};

const withCarousel = ({ config, Component, isIncludeSwperButtons }: WithCarouselProps) => {
  const WithCarousel = <DataT extends ObjectWithId>({ data }: InnerWithCarouselProps<DataT[]>) => {
    const slidesPerView = useResponsibleSlidesPerView(config, data);
    return (
      <Swiper {...config} slidesPerView={slidesPerView}>
        {isIncludeSwperButtons && <SwiperButtons />}
        {data.map((item) => (
          <SwiperSlide key={item.id}>
            <Component {...item} />
          </SwiperSlide>
        ))}
      </Swiper>
    );
  };
  return WithCarousel;
};

export default withCarousel;
