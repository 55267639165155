import { Box, Typography } from '@mui/material';

type OrderItemProps = { title?: string; value?: string };

export const OrderItem = ({ title, value }: OrderItemProps) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <Typography component="p" variant="h5" sx={{ mr: '1rem' }}>
        {title}:
      </Typography>
      <Typography component="p" variant="h6">
        {value}
      </Typography>
    </Box>
  );
};
