import { Button, useTheme } from '@mui/material';
import ToArchiveSvg from '../../svgIcons/ToArchiveSvg';

const ArchiveBtn = ({ onClick }: { onClick: () => void }) => {
  const handleClick = () => {
    onClick();
  };

  const theme = useTheme();

  return (
    <Button variant="outlined" color="warning" onClick={handleClick}>
      <ToArchiveSvg width="25px" height="25px" stroke={theme.palette.warning.light} />
    </Button>
  );
};

export default ArchiveBtn;
