import { apiSlice, ApiTagTypes } from '../../../api/apiSlice/apiSlice';
import { CreateTariffDto, ITariff, UpdateTariffDto } from '../contracts.tariff';

export const tariffApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTariff: builder.query<ITariff, string>({
      query: (id) => ({
        url: 'tariff/' + id,
        method: 'GET',
        providesTags: [ApiTagTypes.tariff],
      }),
    }),
    createTariff: builder.mutation<ITariff, CreateTariffDto>({
      query: (createTariffDto) => ({
        url: 'tariff/',
        method: 'POST',
        body: { ...createTariffDto },
        invalidatesTags: [ApiTagTypes.tariff],
      }),
    }),
    updateTariff: builder.mutation<ITariff, UpdateTariffDto & { id: string }>({
      query: ({ id, ...dto }) => ({
        url: `tariff/${id}`,
        method: 'PUT',
        body: { ...dto },
        invalidatesTags: [ApiTagTypes.tariff],
      }),
    }),
    archiveTariff: builder.mutation<ITariff, string>({
      query: (id) => ({
        url: `tariff/archive/${id}`,
        method: 'PUT',
        invalidatesTags: [ApiTagTypes.tariff],
      }),
    }),
    deleteTariff: builder.mutation<void, string>({
      query: (id) => ({
        url: `tariff/${id}`,
        method: 'DELETE',
        invalidatesTags: [ApiTagTypes.tariff],
      }),
    }),
  }),
});

export const {
  useCreateTariffMutation,
  useArchiveTariffMutation,
  useDeleteTariffMutation,
  useUpdateTariffMutation,
  useGetTariffQuery,
} = tariffApiSlice;
