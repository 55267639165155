import { INews } from '../../../../storeSlices/newsSlice/contracts.news';

export const getNewsFormDefaultValues = (news?: INews) =>
  news
    ? {
        title: news.title,
        content: news.content,
        files: [news.backgroundImage],
      }
    : { title: '', content: '', files: [] };
