import { Box, Button, Typography } from '@mui/material';
import { teal } from '@mui/material/colors';

import TariffCarousel from '../tariffCarousel';
import tariffContentTheme from './tariffContentTheme';

import { ITariff } from '../../storeSlices/tariffSlice/contracts.tariff';
import withTheme from '../withTheme';
import { useNavigate } from 'react-router';

type TariffsContentProps = {
  title: string;
  tariffs: ITariff[];
};

const TariffsContent = ({ title, tariffs }: TariffsContentProps) => {
  const navigate = useNavigate();
  return (
    <>
      <Typography component="h1" variant="h3" textAlign="center" mb="3rem" color={teal[500]}>
        {title}
      </Typography>
      <TariffCarousel data={tariffs} />
      <Box
        sx={{ display: 'flex', justifyContent: 'center', marginY: '5rem' }}
        onClick={() => navigate('/allTariffs', { replace: true })}
      >
        <Button variant="outlined" sx={{ width: '300px', p: '15px', marginX: 'auto' }}>
          Смотреть все тарифы
        </Button>
      </Box>
    </>
  );
};

export default withTheme<TariffsContentProps>({
  theme: tariffContentTheme,
  Component: TariffsContent,
});
