import * as yup from 'yup';
import { InferType } from 'yup';
import { checkFilesFormat, checkFilesSize } from '../../../utils/checkFiles';
import { dateErrorMsg, requiredFieldMessage } from '../../../utils/constants';
import { FILE_SIZE, fileFormats, maxDate, minDate } from './constants';

export const orderFormSchema = yup.object().shape({
  address: yup
    .string()
    .required(requiredFieldMessage)
    .matches(/^[а-яА-ЯёЁ_\-., ]+[0-9]+$/, 'Кирилица и числа'),
  tariffId: yup.string().required(requiredFieldMessage).uuid(),
  districtId: yup.string().required(requiredFieldMessage).uuid(),
  gender: yup.string().required(requiredFieldMessage),
  phoneNumber: yup.string().required(requiredFieldMessage),
  familyName: yup
    .string()
    .required(requiredFieldMessage)
    .matches(/^[а-яА-ЯёЁ]+$/, 'Кирилица'),
  personalName: yup
    .string()
    .required(requiredFieldMessage)
    .matches(/^[а-яА-ЯёЁ]+$/, 'Кирилица'),
  lastName: yup
    .string()
    .required(requiredFieldMessage)
    .matches(/^[а-яА-ЯёЁ]+$/, 'Кирилица'),
  passportSeries: yup
    .string()
    .required(requiredFieldMessage)
    .matches(/^[0-9]+$/, 'Только цифры')
    .min(4, 'Должно быть четыре символа')
    .max(4, 'Должно быть четыре символа'),
  passportNumber: yup
    .string()
    .required(requiredFieldMessage)
    .matches(/^[0-9]+$/, 'Только цифры')
    .min(6, 'Должно быть шесть символов')
    .max(6, 'Должно быть шесть символов'),
  passportBranch: yup
    .string()
    .required(requiredFieldMessage)
    .matches(/^[а-яА-ЯёЁ0-9_\-., ]+$/, 'Кирилица'),
  addressRegistration: yup
    .string()
    .required(requiredFieldMessage)
    .matches(/^[а-яА-ЯёЁ0-9_\-., ]+$/, 'Кирилица'),
  birthday: yup
    .date()
    .required(requiredFieldMessage)
    .min(minDate, dateErrorMsg)
    .max(maxDate, dateErrorMsg)
    .typeError(dateErrorMsg),
  passportDate: yup
    .date()
    .required(requiredFieldMessage)
    .min(minDate, dateErrorMsg)
    .max(maxDate, dateErrorMsg)
    .typeError(dateErrorMsg),
  passportMainPagePhoto: yup
    .mixed<File[]>()
    .required()
    .test('length', 'Выберите одно фото', (values) => values?.length === 1)
    .test('fileFormat', 'Неподдерживаемый формат', (files: File[]) =>
      checkFilesFormat(files, fileFormats)
    )
    .test('fileSize', 'Размер файла слишкмо большой', (files: File[]) =>
      checkFilesSize(files, FILE_SIZE)
    ),
  passportRegistrationPhoto: yup
    .mixed<File[]>()
    .test('length', 'Выберите одно фото', (values) => values?.length === 1)
    .required()
    .test('length', 'Выберите одно фото', (values) => values?.length === 1)
    .test('fileFormat', 'Неподдерживаемый формат', (files: File[]) =>
      checkFilesFormat(files, fileFormats)
    )
    .test('fileSize', 'Размер файла слишкмо большой', (files: File[]) =>
      checkFilesSize(files, FILE_SIZE)
    ),
  aggreddToShareDate: yup.boolean().required().oneOf([true], requiredFieldMessage),
});

export type OrderFormData = Omit<
  InferType<typeof orderFormSchema>,
  'passportMainPagePhoto' | 'passportRegistrationPhoto'
> & {
  passportRegistrationPhoto: File[] | string[];
  passportMainPagePhoto: string[] | File[];
};
