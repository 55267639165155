import { Box } from '@mui/material';
import { AboutUs, NewsCarousel, Tariffs } from '../../components';

import { useAppSelector } from '../../hooks';
import { selectedNews } from '../../storeSlices/newsSlice';
import { selectedWiredTariffs, selectedWireLessTariffs } from '../../storeSlices/tariffSlice';

const MainPage = () => {
  const news = useAppSelector(selectedNews);
  const wiredTariffs = useAppSelector(selectedWiredTariffs);
  const wireLessTariffs = useAppSelector(selectedWireLessTariffs);

  return (
    <>
      {!!news.length && (
        <Box sx={{ mb: '2rem' }}>
          <NewsCarousel data={news} />
        </Box>
      )}
      {(!!wiredTariffs.length || !!wireLessTariffs.length) && (
        <Tariffs wiredTariffs={wiredTariffs} wireLessTariffs={wireLessTariffs} />
      )}
      <Box sx={{ marginBottom: '10rem', marginTop: '2rem' }}>
        <AboutUs />
      </Box>
    </>
  );
};

export default MainPage;
