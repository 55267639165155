import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useCallback, useState } from 'react';

import MyPagination from '../../../components/pagination';
import Spinner from '../../../components/spinner/Spinner';
import Dialog from '../../../components/dialog';

import { calculateIndex } from '../../../utils/calculateIndex';
import { useCurrentItems, useEnqueueMsg } from '../../../hooks';
import { getMaxPage } from '../../../storeSlices/utils/getMaxpPage';
import { districtSuccessfullyDeleted } from '../../../utils/messages';
import {
  useDeleteDistrictMutation,
  useGetDistrictsQuery,
} from '../../../api/district/query/districtApiSlice';
import { AddBtn, DeleteBtn, EditBtn } from '../../../components/buttons';

const AdminkaDistricts = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteDistrict] = useDeleteDistrictMutation();

  const { isLoading, data: districts } = useGetDistrictsQuery();
  const districtsPerPage = 10;
  const maxPage = getMaxPage(districts?.length ?? 0, districtsPerPage);

  const currentDistricts = useCurrentItems(currentPage, districtsPerPage, districts ?? []);
  const handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<null | string>(null);

  const closeDialogWindow = () => setIsDialogOpen(false);

  const handleDeleteClick = (id: string) => {
    setSelectedId(id);
    setIsDialogOpen(true);
  };

  const removeDistrict = useCallback(async () => {
    if (selectedId) {
      await deleteDistrict(selectedId).unwrap();
      closeDialogWindow();
    }
  }, [deleteDistrict, selectedId]);

  const onDialogOk = useEnqueueMsg({
    fn: removeDistrict,
    msg: districtSuccessfullyDeleted.message,
    onError: closeDialogWindow,
  });

  const onDialogCancel = () => {
    setSelectedId(null);
    setIsDialogOpen(!isDialogOpen);
  };

  return (
    <>
      <Box sx={{ mb: '2rem' }}>
        <AddBtn link="/adminka/district/create" />
      </Box>
      <Typography variant="h4" component="h1" textAlign="center" sx={{ mb: '2rem' }}>
        Населенные пункты
      </Typography>

      {isLoading && <Spinner open={true} />}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: '20px', fontWeight: 500 }}>№</TableCell>
              <TableCell sx={{ fontSize: '20px', fontWeight: 500 }}>Название</TableCell>
              <TableCell sx={{ fontSize: '20px', fontWeight: 500 }}>Код</TableCell>
              <TableCell sx={{ fontSize: '20px', fontWeight: 500 }}>Действия</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentDistricts.map((district, index) => (
              <TableRow
                key={district.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell sx={{ fontSize: '18px' }} component="th" scope="row">
                  {calculateIndex({
                    itemsPerPage: districtsPerPage,
                    index,
                    currentPage,
                  })}
                </TableCell>
                <TableCell sx={{ fontSize: '18px' }} component="th" scope="row">
                  {district.title}
                </TableCell>
                <TableCell sx={{ fontSize: '18px' }} component="th" scope="row">
                  {district.code}
                </TableCell>
                <TableCell align="right">
                  <Box sx={{ display: 'flex', columnGap: '5px' }}>
                    <EditBtn link={`/adminka/district/edit/${district.id}`} />
                    <DeleteBtn onClick={() => handleDeleteClick(district.id)} />
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog isOpen={isDialogOpen} onOk={onDialogOk} onCancel={onDialogCancel} />
      <MyPagination maxPage={maxPage} currentPage={currentPage} onChange={handlePaginationChange} />
    </>
  );
};

export default AdminkaDistricts;
