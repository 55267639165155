import { blue } from '@mui/material/colors';
import { ListItemProps } from '../../../components/SideMenu/MyListItem';

export const profileSideMenuItems: ListItemProps[] = [
  { text: 'Профиль', link: '/profile/main' },
  {
    text: 'Счет',
    link: '/profile/account',
  },
  {
    text: 'Оплата',
    link: '/profile/payment',
  },
  { text: 'Доверительный платеж', link: '/profile/confidential-payment' },
  { text: 'Автоматическое списание', link: '/profile/auto-payment' },
  {
    text: 'Главное меню',
    link: '/',
    color: blue[800],
  },
];
