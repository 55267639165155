import { TextField } from '@mui/material';
import { ForwardedRef, forwardRef } from 'react';

type InputProps = {
  label: string;
  placeholder: string;
  error: boolean;
  onKeyDown?: () => void;
  helperText?: string;
  rows?: number;
};

export const Input = forwardRef((props: InputProps, ref: ForwardedRef<HTMLInputElement>) => {
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key.toLocaleLowerCase() === 'enter') {
      e.preventDefault();
      props.onKeyDown && props.onKeyDown();
    }
  };
  return (
    <TextField
      {...props}
      fullWidth
      multiline
      variant="outlined"
      inputRef={ref}
      onKeyDown={handleKeyDown}
    />
  );
});
