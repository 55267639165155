import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../store/store.contracts';
import { createNewsThunk, deleteNewsThunk, fetchNewsThunk, updateNewsThunk } from './thunks';

import { INews } from './contracts.news';

import { getMaxPage } from '../utils/getMaxpPage';
import { newsPerPage } from '../utils/constants';

export type NewsStoreInitialState = {
  data: INews[];
  isLoading: boolean;
  maxPage: number;
};

const newsStoreInitialState: NewsStoreInitialState = {
  data: [],
  isLoading: false,
  maxPage: 0,
};

const newsStoreSlice = createSlice({
  name: 'news',
  initialState: newsStoreInitialState,
  reducers: {
    addNews: (state, { payload }: PayloadAction<INews>) => {
      state.data = [...state.data, payload];
      state.maxPage = getMaxPage(state.data.length, newsPerPage);
    },
    updateNews: (state, { payload }: PayloadAction<INews>) => {
      state.data = state.data.map((news) => {
        if (payload.id !== news.id) {
          return news;
        }
        return { ...news, ...payload };
      });
    },
    removeNews: (state, { payload: id }: PayloadAction<string>) => {
      state.data = state.data.filter((news) => news.id !== id);
      state.maxPage = getMaxPage(state.data.length, newsPerPage);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNewsThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchNewsThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
      state.maxPage = getMaxPage(payload.length, newsPerPage);
    });
    builder.addCase(fetchNewsThunk.rejected, (state) => {
      state.isLoading = false;
      state.maxPage = 0;
    });
    builder.addCase(createNewsThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createNewsThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (!payload) {
        return;
      }
      state.data = [...state.data, payload];
      state.maxPage = getMaxPage(state.data.length, newsPerPage);
    });
    builder.addCase(createNewsThunk.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateNewsThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateNewsThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = state.data.map((news) => {
        if (payload?.id !== news.id) {
          return news;
        }
        return { ...news, ...payload };
      });
    });
    builder.addCase(updateNewsThunk.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteNewsThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteNewsThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = state.data.filter((news) => news.id !== payload);
      state.maxPage = getMaxPage(state.data.length, newsPerPage);
    });
    builder.addCase(deleteNewsThunk.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const selectedNews = (state: RootState) => state.news.data;
export const selectedOneNews = (id: string) => (state: RootState) =>
  state.news.data.find((news) => news.id === id);
export const selectedIsNewsLoading = (state: RootState) => state.news.isLoading;
export const selectedNewsMaxPage = (state: RootState) => state.news.maxPage;
export const { addNews, updateNews, removeNews } = newsStoreSlice.actions;
export default newsStoreSlice.reducer;
