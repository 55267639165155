import { Box, Pagination } from '@mui/material';

type PaginationProps = {
  maxPage: number;
  currentPage: number;
  onChange: (event: React.ChangeEvent<unknown>, value: number) => void;
};
const MyPagination = ({ maxPage, currentPage, onChange }: PaginationProps) => {
  return (
    <Box
      sx={{
        mt: '3rem',
        paddingY: '2rem',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Pagination
        count={maxPage}
        page={currentPage}
        onChange={onChange}
        variant="outlined"
        color="primary"
      />
    </Box>
  );
};

export default MyPagination;
