import safeJsonParse from './safeJsonParse';

export const getSessionStoreValue = <T>(key: string, guard: (o: unknown) => o is T) => {
  const item = sessionStorage.getItem(key);

  if (item) {
    const parsedItem = safeJsonParse<T>(guard)(item);
    return parsedItem;
  }
  return null;
};

export const setSessionStoreItem = <T>(key: string, item: T) => {
  sessionStorage.setItem(key, JSON.stringify(item));
};

export const clearSessionStore = () => sessionStorage.clear();
