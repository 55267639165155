import * as yup from 'yup';
import { InferType } from 'yup';
import { requiredFieldMessage } from '../../utils/constants';

const minMsg = 'Миниму 4 символа';
const accessibleSymbols = 'Допустимые символы: A-Z 0-9 .,_-';

const schema = yup.object().shape({
  login: yup
    .string()
    .required(requiredFieldMessage)
    .min(4, minMsg)
    .matches(/^[a-z_\-.,0-9 ]+$/, accessibleSymbols),
  password: yup
    .string()
    .required(requiredFieldMessage)
    .min(4, minMsg)
    .matches(/^[a-z_\-.,0-9 ]+$/, accessibleSymbols),
  remember: yup.boolean().required().oneOf([true, false], requiredFieldMessage),
});

export type AuthFormData = InferType<typeof schema>;

export default schema;
