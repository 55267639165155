import { Control, FieldErrors } from 'react-hook-form';
import { SelectOption } from '../../utils/toSelectOptions';
import CustomPhoneNumberInp from '../customInputs/NumberInput';
import SelectInput from '../customInputs/SelectInput';

import { OrderFormData } from '../../view/orderPage/helpers/orderFormSchema';

const OrderFormCommon = ({
  errors,
  control,
  districtsOptions,
  tariffsOptions,
}: {
  districtsOptions: SelectOption[];
  tariffsOptions: SelectOption[];
  errors: FieldErrors<OrderFormData>;
  control: Control<OrderFormData>;
}) => {
  return (
    <>
      <SelectInput<OrderFormData>
        control={control}
        name="districtId"
        title="Населенный пункт"
        label="Выберите ваш населенный пункт"
        options={districtsOptions}
      />
      <SelectInput<OrderFormData>
        label="Выберите тариф"
        name="tariffId"
        control={control}
        title="Тариф"
        options={tariffsOptions}
      />
      <CustomPhoneNumberInp<OrderFormData>
        name="phoneNumber"
        control={control}
        label="Номер телефона"
        error={!!errors.phoneNumber}
      />
    </>
  );
};

export default OrderFormCommon;
