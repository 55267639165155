import { Button, useTheme } from '@mui/material';
import { useNavigate } from 'react-router';
import EditSvg from '../../svgIcons/EditSvg';

const EditBtn = ({ link }: { link: string }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(link);
  };

  const theme = useTheme();

  return (
    <Button variant="outlined" color="success" onClick={handleClick}>
      <EditSvg width="25px" height="25px" fill={theme.palette.success.main} />
    </Button>
  );
};

export default EditBtn;
