import { useParams } from 'react-router';

import Spinner from '../../../../components/spinner/Spinner';
import { DistrictFormData } from './yupSchema';
import { districtSuccessfullyUpdated } from '../../../../utils/messages';
import {
  useGetOneDistrictQuery,
  useUpdateDistrictMutation,
} from '../../../../api/district/query/districtApiSlice';
import DistrictFormPresent from './DistrictFormPresent';
import { useCallback } from 'react';
import useEnqueueMsgWithData from '../../../../hooks/useEnqueueMsg/useEnqueueMsgWithData';

export default function DistrictFormEdit() {
  const { id } = useParams();
  const [updateDistrict] = useUpdateDistrictMutation();
  const { data: district, isLoading } = useGetOneDistrictQuery(id as string);
  const title = 'Редактировать район';

  const defaultValues = {
    title: district?.title ?? '',
    code: district?.code ?? '',
  };

  const fn = useCallback(
    async (data: DistrictFormData) => {
      id && (await updateDistrict({ id, updateDistrictDto: data }).unwrap());
    },
    [id, updateDistrict]
  );

  const onSubmit = useEnqueueMsgWithData<DistrictFormData>({
    fn,
    msg: districtSuccessfullyUpdated.message,
  });

  return (
    <>
      {isLoading ? (
        <Spinner open={true} />
      ) : (
        <DistrictFormPresent title={title} defaultValues={defaultValues} onSubmit={onSubmit} />
      )}
    </>
  );
}
