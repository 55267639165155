import { Navigate, Outlet, useLocation } from 'react-router';
import { useAuth, useRoles } from '../../hooks';

const RequireAuth = ({ requiredRoles }: { requiredRoles: string[] }) => {
  const location = useLocation();
  const tokens = useAuth();
  const roles = useRoles();

  const haveAccess = roles?.find((role) => requiredRoles.includes(role));

  return haveAccess ? (
    <Outlet />
  ) : tokens ? (
    <Navigate to="/forbidden" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
