import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useState } from 'react';

import { calculateIndex } from '../../../utils/calculateIndex';
import { useCurrentItems } from '../../../hooks';
import { getMaxPage } from '../../../storeSlices/utils/getMaxpPage';
import Spinner from '../../../components/spinner/Spinner';
import MyPagination from '../../../components/pagination';
import { AddBtn, EditBtn } from '../../../components/buttons';
import MoreBtn from '../../../components/buttons/moreBtn';
import BtnLink from '../../../components/btnLink';
import { IOrder } from '../../../api/order/orderApi';

const AdminkaOrdersPresent = ({
  orders,
  isLoading,
  title,
}: {
  title: string;
  orders?: IOrder[];
  isLoading: boolean;
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const ordersPerPage = 10;
  const maxPage = getMaxPage(orders?.length ?? 0, ordersPerPage);

  const currentOrders = useCurrentItems(currentPage, ordersPerPage, orders ?? []);
  const handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  return (
    <>
      <Box sx={{ display: 'flex', mb: '5rem', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', columnGap: '1rem' }}>
          <BtnLink link="/adminka/orders" title="Активные" />
          <BtnLink link="/adminka/orders/archived" title="Обработанные" />
          <BtnLink link="/adminka/orders/canceled" title="Откланенные" />
        </Box>
        <AddBtn link="/order" />
      </Box>

      {isLoading && <Spinner open={true} />}
      <Typography variant="h4" component="h1" textAlign="center" sx={{ mb: '2rem' }}>
        {title}
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: '20px', fontWeight: 500 }}>№</TableCell>
              <TableCell sx={{ fontSize: '20px', fontWeight: 500 }}>ФИО заказчика</TableCell>
              <TableCell sx={{ fontSize: '20px', fontWeight: 500 }}>Действия</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentOrders.map((order, index) => (
              <TableRow key={order.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell sx={{ fontSize: '18px' }} component="th" scope="row">
                  {calculateIndex({
                    itemsPerPage: ordersPerPage,
                    index,
                    currentPage,
                  })}
                </TableCell>
                <TableCell sx={{ fontSize: '18px' }} component="th" scope="row">
                  {`${order.familyName} ${order.personalName} ${order.lastName}`}
                </TableCell>
                <TableCell align="right">
                  <Box sx={{ display: 'flex', columnGap: '5px' }}>
                    <MoreBtn link={`/adminka/order/${order.id}/`} />
                    <EditBtn link={`/adminka/order/edit/${order.id}/`} />
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <MyPagination maxPage={maxPage} currentPage={currentPage} onChange={handlePaginationChange} />
    </>
  );
};

export default AdminkaOrdersPresent;
