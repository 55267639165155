import { useEffect, useState } from 'react';
import { selectedIsNewsLoading } from '../../storeSlices/newsSlice';
import { selectedIsTariffLoading } from '../../storeSlices/tariffSlice';
import { useAppSelector } from '../useStoreHooks/useStoreHooks';

const useIsStoreLoading = () => {
  const [isLoading, setIsLoading] = useState(true);
  const isNewsLoading = useAppSelector(selectedIsNewsLoading);
  const isTariffsLoading = useAppSelector(selectedIsTariffLoading);

  useEffect(() => {
    if (isNewsLoading || (isTariffsLoading && !isLoading)) {
      setIsLoading(true);
    } else if (!isNewsLoading && !isTariffsLoading && isLoading) {
      setIsLoading(false);
    }
  }, [isTariffsLoading, isNewsLoading, isLoading]);
  return isLoading;
};

export default useIsStoreLoading;
