import { v4 as uuidv4 } from 'uuid';
import { toString } from './toString';

export type SelectOption = { key: string; value: string };
export const toSelectOptions = <DataT extends { id: string }>({
  arr,
  key,
}: {
  arr: DataT[];
  key: keyof DataT & string;
}): SelectOption[] => {
  if (!arr.length) {
    return [{ key: 'Ничего не найдено', value: uuidv4() }];
  }
  return arr.map((item) => ({ key: toString(item[key]), value: item.id }));
};
