import { Control, FieldErrors, UseFormRegister } from 'react-hook-form';
import { Input } from '../customInputs/Input';
import SelectInput from '../customInputs/SelectInput';
import DateInput from '../dateInput';

import { OrderFormData } from '../../view/orderPage/helpers/orderFormSchema';

const OrderFormPassport = ({
  errors,
  register,
  control,
}: {
  errors: FieldErrors<OrderFormData>;
  register: UseFormRegister<OrderFormData>;
  control: Control<OrderFormData>;
}) => {
  return (
    <>
      <Input
        {...register('familyName')}
        label="Фамилия"
        placeholder="Введите вашу фамилию..."
        error={!!errors.familyName}
        helperText={errors.familyName?.message}
      />
      <Input
        {...register('personalName')}
        label="Имя"
        placeholder="Введите ваше имя..."
        error={!!errors.personalName}
        helperText={errors.personalName?.message}
      />

      <Input
        {...register('lastName')}
        label="Отчество"
        placeholder="Введите ваше отчество..."
        error={!!errors.lastName}
        helperText={errors.lastName?.message}
      />
      <Input
        {...register('passportSeries')}
        label="Серия пасспорта"
        placeholder="Введите серию пасспорта..."
        error={!!errors.passportSeries}
        helperText={errors.passportSeries?.message}
      />
      <Input
        {...register('passportNumber')}
        label="Номер пааспорта"
        placeholder="Введите номер пасспорта..."
        error={!!errors.passportNumber}
        helperText={errors.passportNumber?.message}
      />
      <Input
        {...register('passportBranch')}
        label="Кем выдан ваш пасспорт"
        placeholder="Введите название органа выдавщий пааспорт..."
        error={!!errors.passportBranch}
        helperText={errors.passportBranch?.message}
      />
      <Input
        {...register('addressRegistration')}
        label="Место регистрации"
        placeholder="Введите ваше место регистрации..."
        error={!!errors.addressRegistration}
        helperText={errors.addressRegistration?.message}
      />
      <Input
        {...register('address')}
        label="Улица и дом номер"
        placeholder="Введите адрес проживания..."
        error={!!errors.address}
        helperText={errors.address?.message}
      />
      <DateInput<OrderFormData>
        register={register}
        name="birthday"
        label="Выберите дату рождения"
        id="birthday"
        error={!!errors.birthday}
        helperText={errors.birthday?.message}
      />
      <DateInput<OrderFormData>
        register={register}
        name="passportDate"
        label="Выберите дату выдачи пасспорта"
        id="passportDate"
        error={!!errors.passportDate}
        helperText={errors.passportDate?.message}
      />
      <SelectInput<OrderFormData>
        control={control}
        name="gender"
        title="Пол"
        label="Выберите ваш пол"
        options={[
          { value: 'man', key: 'Мужской' },
          { value: 'woman', key: 'Женский' },
        ]}
      />
    </>
  );
};

export default OrderFormPassport;
