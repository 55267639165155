import { useNavigate } from 'react-router';
import { Box, Button, Divider, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { OrderFormData, orderFormSchema } from '../helpers/orderFormSchema';
import { SelectOption } from '../../../utils/toSelectOptions';

import CustomCheckbox from '../../../components/customInputs/Checkbox';
import {
  OrderFormCommon,
  OrderFormImages,
  OrderFormPassport,
} from '../../../components/orderCommonForms';

export default function OrderFormCreatePresent({
  defaultValues,
  districtsOptions,
  tariffsOptions,
  onSubmit,
  title,
}: {
  defaultValues: Omit<OrderFormData, 'birthday' | 'passportDate'>;
  districtsOptions: SelectOption[];
  tariffsOptions: SelectOption[];
  onSubmit: (data: OrderFormData) => void;
  title: string;
}) {
  const navigate = useNavigate();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<OrderFormData>({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(orderFormSchema),
  });

  const onSubmitWrapper = (data: OrderFormData) => {
    onSubmit(data);
    reset(defaultValues);
    navigate('/', { replace: true });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '3rem 1rem',
      }}
    >
      <Typography component="h1" variant="h2" sx={{ mb: '5rem' }}>
        {title}
      </Typography>
      <form onSubmit={handleSubmit(onSubmitWrapper)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '2rem',
            justifyContent: 'center',
          }}
        >
          <Typography component="h1" variant="h4">
            Общая информация
          </Typography>
          <OrderFormCommon
            control={control}
            errors={errors}
            districtsOptions={districtsOptions}
            tariffsOptions={tariffsOptions}
          />
          <Divider />
          <Typography component="h1" variant="h4">
            Паспортные данные
          </Typography>
          <OrderFormPassport control={control} errors={errors} register={register} />
          <OrderFormImages control={control} errors={errors} />
          <CustomCheckbox<OrderFormData>
            label="Согласен на обработку персональных данных в соответствии с 152-ФЗ"
            control={control}
            name="aggreddToShareDate"
            error={!!errors.aggreddToShareDate}
          />
          <Button
            variant="contained"
            type="submit"
            sx={{ width: '200px', mt: '2rem', alignSelf: 'center' }}
          >
            Сохранить
          </Button>
        </Box>
      </form>
    </Box>
  );
}
