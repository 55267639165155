import { Box, Container, Divider, Pagination, Typography } from '@mui/material';
import { teal } from '@mui/material/colors';
import { useState } from 'react';
import { useAppSelector, useCurrentItems } from '../../hooks';
import { ITariff } from '../../storeSlices/tariffSlice/contracts.tariff';
import { selectedTariffs } from '../../storeSlices/tariffSlice/tariffStoreSlice';
import { getMaxPage } from '../../storeSlices/utils/getMaxpPage';
import TariffSimple from '../../components/tariff/TariffSimple';

const tariffsRowPerPage = 10;

const AllTariffs = () => {
  const tariffs = useAppSelector(selectedTariffs);
  const maxPage = getMaxPage(tariffs.length, tariffsRowPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const curretTariffs = useCurrentItems<ITariff>(currentPage, tariffsRowPerPage, tariffs);
  const handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };
  return (
    <Box sx={{ backgroundColor: '#ffffff' }}>
      <Container>
        <Typography
          component="h1"
          variant="h2"
          textAlign="center"
          mt="3rem"
          mb="4rem"
          color={teal[700]}
        >
          Все тарифы
        </Typography>
        <Box sx={{ mY: '5rem' }}>
          {curretTariffs.map((tariff) => (
            <Box key={tariff.id} sx={{ mb: '1rem' }}>
              <TariffSimple tariff={tariff} />
              <Divider />
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            mt: '3rem',
            paddingY: '2rem',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Pagination
            count={maxPage}
            page={currentPage}
            onChange={handlePaginationChange}
            variant="outlined"
            color="primary"
          />
        </Box>
      </Container>
    </Box>
  );
};

export default AllTariffs;
