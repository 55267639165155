import { Box, Container } from '@mui/material';
import { Outlet } from 'react-router';

import { SideMenu } from '../../../components/';
import HeaderTop from '../../../components/withHeader/HeaderTop';
import { adminkaSideMenuItems } from './constants';

const AdminkaLayout = () => {
  return (
    <>
      <HeaderTop />
      <Container disableGutters className="adminka-layout" sx={{ paddingTop: '2rem' }}>
        <Box sx={{ display: 'flex' }}>
          <Box className="adminka__sidebar" sx={{ maxWidth: '200px', mr: '1.8rem' }}>
            <SideMenu listItems={adminkaSideMenuItems} />
          </Box>
          <Box className="adminka__content" sx={{ width: '100%' }}>
            <Outlet />
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default AdminkaLayout;
