import Dropzone from 'react-dropzone';
import { List, ListItem, ListItemIcon, ListItemText, Paper, Typography } from '@mui/material';
import { CloudUpload, InsertDriveFile } from '@mui/icons-material';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { red } from '@mui/material/colors';
import computeColor from '../../utils/computeColor';

type FileInputProps<FormData extends FieldValues> = {
  error: boolean;
  helperText?: string;
  control: Control<FormData, unknown>;
  name: Path<FormData>;
};

export const FileInput = <FormData extends FieldValues>({
  error,
  helperText,
  control,
  name,
}: FileInputProps<FormData>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: true }}
      render={({ field: { onChange, onBlur, value } }) => (
        <>
          <Dropzone onDrop={onChange}>
            {({ getRootProps, getInputProps }) => (
              <Paper
                variant="outlined"
                sx={{
                  backgroundColor: '#eee',
                  textAlign: 'center',
                  cursor: 'pointer',
                  color: computeColor({ isError: error, defaultColor: '#333' }),
                  padding: '10px',
                  border: `2px solid ${computeColor({ isError: error, defaultColor: '#888888' })}`,
                }}
                {...getRootProps()}
              >
                <CloudUpload
                  sx={{
                    marginTop: '16px',
                    color: computeColor({
                      isError: error,
                      defaultColor: '#888888',
                    }),
                    fontSize: '42px',
                  }}
                />
                <input {...getInputProps()} type="file" hidden onBlur={onBlur} />
                <p>Нажмите чтоб выбрать фото</p>
              </Paper>
            )}
          </Dropzone>
          <Typography
            sx={{ ml: '20px', mt: '5px' }}
            color={red[400]}
            component="p"
            variant="caption"
          >
            {helperText}
          </Typography>
          <List>
            {(value as File[]).map((f, ind) => (
              <ListItem key={f.name ?? ind}>
                <ListItemIcon>
                  <InsertDriveFile color={error ? 'error' : 'success'} />
                </ListItemIcon>
                <ListItemText
                  sx={{ color: error ? 'red' : 'success' }}
                  primary={f.name}
                  secondary={f.size}
                />
              </ListItem>
            ))}
          </List>
        </>
      )}
    />
  );
};
