const LoginSvg = () => (
  <svg
    className="login-svg signin-svg"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="-0.5 0 24 24"
  >
    <path
      fillRule="evenodd"
      d="M11 4.77V7a1.5 1.5 0 0 1-3 0V4.347a2.5 2.5 0 0 1 2.089-2.466l10-1.666A2.5 2.5 0 0 1 23 2.68V21.32a2.5 2.5 0 0 1-2.911 2.466l-10-1.667A2.5 2.5 0 0 1 8 19.653V17a1.5 1.5 0 0 1 3 0v2.23l9 1.5V3.27l-9 1.5zm2.379 8.73H1.5a1.5 1.5 0 0 1 0-3h11.879l-.44-.44a1.5 1.5 0 0 1 2.122-2.12l3 3a1.5 1.5 0 0 1 0 2.12l-3 3a1.5 1.5 0 0 1-2.122-2.12l.44-.44z"
      clipRule="evenodd"
    />
  </svg>
);
export default LoginSvg;
