import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateTariff } from '../../../api/tariff/tariffApi';
import { UpdateTariffDto } from '../contracts.tariff';

const updateTariffThunk = createAsyncThunk(
  'tariff/updateTariffThunk',
  async ({ updateTariffDto, id }: { updateTariffDto: UpdateTariffDto; id: string }) => {
    const updatedTariff = await updateTariff(id, updateTariffDto);
    return updatedTariff;
  }
);

export default updateTariffThunk;
