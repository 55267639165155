const ArrowSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="arrow-svg"
    viewBox="0 0 32 32"
    xmlSpace="preserve"
  >
    <g>
      <path d="M16 32c8.822 0 16-7.178 16-16S24.822 0 16 0 0 7.178 0 16s7.178 16 16 16zm0-31c8.271 0 15 6.729 15 15s-6.729 15-15 15S1 24.271 1 16 7.729 1 16 1z" />
      <path d="M13.224 21.2a.498.498 0 0 0 .708 0l4.846-4.846a.5.5 0 0 0 0-.707L13.931 10.8a.5.5 0 0 0-.707.707L17.716 16l-4.492 4.493a.499.499 0 0 0 0 .707z" />
    </g>
  </svg>
);

export default ArrowSvg;
