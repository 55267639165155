import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { SnackbarProvider } from 'notistack';

import Spinner from './components/spinner/Spinner';
import { useInitializeStore, useIsStoreLoading } from './hooks';
import { RouterView } from './router';

const queryClient = new QueryClient();

function App() {
  const isLoading = useIsStoreLoading();
  useInitializeStore();

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <SnackbarProvider maxSnack={4} autoHideDuration={5000}>
          {isLoading ? (
            <Spinner open={true} />
          ) : (
            <div className="App">
              <RouterView />
            </div>
          )}
        </SnackbarProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
