import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { useAppDispatch, useAppSelector, useCurrentItems, useEnqueueMsg } from '../../../../hooks';
import { selectedNews } from '../../../../storeSlices/newsSlice';
import formatDate from '../../../../utils/formatDate';
import { useCallback, useState } from 'react';
import Dialog from '../../../../components/dialog';
import { calculateIndex } from '../../../../utils/calculateIndex';
import { newsPerPage } from '../../../../storeSlices/utils/constants';
import { removeNews, selectedNewsMaxPage } from '../../../../storeSlices/newsSlice/newsStoreSlice';
import { newsSuccessfullyDeleted } from '../../../../storeSlices/utils/successMessages.constants';
import { useDeleteNewsMutation } from '../../../../storeSlices/newsSlice/query/newsApiSlice';
import { cutStringBySymbols } from '../../../../utils/cutString';
import MyPagination from '../../../../components/pagination';
import { AddBtn, DeleteBtn, EditBtn } from '../../../../components/buttons';

const AdminkaNews = () => {
  const news = useAppSelector(selectedNews);
  const maxPage = useAppSelector(selectedNewsMaxPage);
  const dispatch = useAppDispatch();

  const [deleteNews] = useDeleteNewsMutation();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<null | string>(null);

  const handleDeleteClick = (id: string) => {
    setSelectedId(id);
    setIsDialogOpen(!isDialogOpen);
  };

  const closeDialogWindow = () => {
    setSelectedId(null);
    setIsDialogOpen(false);
  };
  const rmNews = useCallback(async () => {
    if (selectedId) {
      await deleteNews(selectedId).unwrap();
      dispatch(removeNews(selectedId));
      closeDialogWindow();
    }
  }, [deleteNews, dispatch, selectedId]);

  const onDialogOk = useEnqueueMsg({
    fn: rmNews,
    msg: newsSuccessfullyDeleted,
    onError: closeDialogWindow,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const currentNews = useCurrentItems(currentPage, newsPerPage, news);
  const handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  return (
    <>
      <Box sx={{ mb: '2rem' }}>
        <AddBtn link="/adminka/news/create" />
      </Box>
      <Typography variant="h4" component="h1" textAlign="center" sx={{ mb: '2rem' }}>
        Новости
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: '20px', fontWeight: 500 }}>№</TableCell>
              <TableCell sx={{ fontSize: '20px', fontWeight: 500 }}>Название</TableCell>
              <TableCell sx={{ fontSize: '20px', fontWeight: 500 }} align="right">
                Контент
              </TableCell>
              <TableCell sx={{ fontSize: '20px', fontWeight: 500 }} align="right">
                Создано
              </TableCell>
              <TableCell sx={{ fontSize: '20px', fontWeight: 500 }} align="right">
                Изображение
              </TableCell>
              <TableCell sx={{ fontSize: '20px', fontWeight: 500 }} align="center">
                Действия
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentNews.map((newsItem, index) => (
              <TableRow
                key={newsItem.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell sx={{ fontSize: '18px' }} component="th" scope="row">
                  {calculateIndex({
                    itemsPerPage: newsPerPage,
                    index,
                    currentPage,
                  })}
                  )
                </TableCell>
                <TableCell sx={{ fontSize: '18px' }} component="th" scope="row">
                  {newsItem.title}
                </TableCell>
                <TableCell sx={{ fontSize: '18px' }} align="right">
                  {cutStringBySymbols(newsItem.content, 20)}
                </TableCell>
                <TableCell sx={{ fontSize: '18px' }} align="right">
                  {formatDate(newsItem.createdAt)}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: '18px',
                    maxWidth: '100px',
                    maxHeight: '100px',
                  }}
                  align="right"
                >
                  <img src={newsItem.backgroundImage} alt="backgroundImage" />
                </TableCell>

                <TableCell align="right">
                  <Box sx={{ display: 'flex', columnGap: '5px' }}>
                    <EditBtn link={`/adminka/news/edit/${newsItem.id}`} />
                    <DeleteBtn onClick={() => handleDeleteClick(newsItem.id)} />
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog isOpen={isDialogOpen} onOk={onDialogOk} onCancel={closeDialogWindow} />
      <MyPagination maxPage={maxPage} currentPage={currentPage} onChange={handlePaginationChange} />
    </>
  );
};

export default AdminkaNews;
