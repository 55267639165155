import MuiPhoneNumber from 'mui-phone-number';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

type PhoneNumberProps<FormData extends FieldValues> = {
  error?: boolean;
  control: Control<FormData, unknown>;
  name: Path<FormData>;
  label: string;
  defaultCountry?: string;
  onlyCountries?: string[];
  helperText?: string;
};

const CustomPhoneNumberInp = <FormData extends FieldValues>({
  error = false,
  control,
  name,
  label,
  defaultCountry = 'ru',
  onlyCountries = ['ru'],
  helperText,
}: PhoneNumberProps<FormData>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: true }}
      render={({ field: { onChange } }) => (
        <MuiPhoneNumber
          variant="outlined"
          defaultCountry={defaultCountry}
          onlyCountries={onlyCountries}
          label={label}
          error={error}
          helperText={helperText}
          onChange={onChange}
        />
      )}
    />
  );
};

export default CustomPhoneNumberInp;
