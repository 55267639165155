import { DistrictFormData } from './yupSchema';
import { districtSuccessfullyCreated } from '../../../../utils/messages';
import { useCreateDistrictMutation } from '../../../../api/district/query/districtApiSlice';
import DistrictFormPresent from './DistrictFormPresent';
import { useCallback } from 'react';
import useEnqueueMsgWithData from '../../../../hooks/useEnqueueMsg/useEnqueueMsgWithData';

export default function DistrictFormCreate() {
  const [createDistrict] = useCreateDistrictMutation();
  const title = 'Создать район';

  const defaultValues = { code: '', title: '' };

  const fn = useCallback(
    async (data: DistrictFormData) => {
      await createDistrict(data).unwrap();
    },
    [createDistrict]
  );

  const onSubmit = useEnqueueMsgWithData<DistrictFormData>({
    fn,
    msg: districtSuccessfullyCreated.message,
  });

  return <DistrictFormPresent title={title} defaultValues={defaultValues} onSubmit={onSubmit} />;
}
