export const fileFormats = ['image/jpg', 'image/jpeg', 'image/png'];
export const FILE_SIZE = 10000 * 1024;

export const checkFilesFormat = (files: File[], formats = fileFormats) => {
  if (files) {
    return files.every((file) => {
      if (typeof file === 'string') {
        return true;
      }
      return formats.includes(file.type);
    });
  }
  return false;
};

export const checkFilesSize = (files: File[], fileSize = FILE_SIZE) => {
  if (files) {
    return files.every((file) => {
      if (typeof file === 'string') {
        return true;
      }
      return file.size <= fileSize;
    });
  }
  return false;
};
