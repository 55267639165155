import { Link, Typography } from '@mui/material';

export default function Copyright({ marginTop }: { marginTop: number }) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      sx={{ marginTop: marginTop + 'rem' }}
    >
      {'Copyright © '}
      <Link color="inherit" href="/">
        Daymohk95
      </Link>
      {' ' + new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
