import { apiSlice, ApiTagTypes } from '../../apiSlice/apiSlice';
import { CreateDistrictDto, IDistrcit, UpdateDistrcitDto } from '../districtApi';

export const districtApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOneDistrict: builder.query<IDistrcit, string>({
      query: (id) => ({
        url: 'district/' + id,
        method: 'GET',
      }),
      keepUnusedDataFor: 60,
      providesTags: [ApiTagTypes.district],
    }),
    getDistricts: builder.query<IDistrcit[], void>({
      query: () => ({
        url: 'district/',
        method: 'GET',
      }),
      keepUnusedDataFor: 60,
      providesTags: [ApiTagTypes.district],
    }),
    createDistrict: builder.mutation<IDistrcit, CreateDistrictDto>({
      query: (createDistrictDto) => ({
        url: 'district/',
        method: 'POST',
        body: { ...createDistrictDto },
      }),
      invalidatesTags: [ApiTagTypes.district],
    }),
    updateDistrict: builder.mutation<
      IDistrcit,
      { updateDistrictDto: UpdateDistrcitDto; id: string }
    >({
      query: ({ id, ...dto }) => ({
        url: 'district/' + id,
        method: 'PUT',
        body: { ...dto.updateDistrictDto },
      }),
      invalidatesTags: [ApiTagTypes.district],
    }),
    deleteDistrict: builder.mutation<void, string>({
      query: (id) => ({
        url: 'district/' + id,
        method: 'DELETE',
      }),
      invalidatesTags: [ApiTagTypes.district],
    }),
  }),
});

export const {
  useGetOneDistrictQuery,
  useCreateDistrictMutation,
  useDeleteDistrictMutation,
  useUpdateDistrictMutation,
  useGetDistrictsQuery,
} = districtApiSlice;
