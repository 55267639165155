import { getJWTPayload } from '../../storeSlices/authSLice/helpers';
import useAuth from '../useAuth';

const useRoles = () => {
  const tokens = useAuth();
  if (!tokens) {
    return null;
  }
  const payload = getJWTPayload(tokens.accessToken);
  return payload.roles;
};

export default useRoles;
