import { useGetActiveOrdersQuery } from '../../../api/order/query/orderApiSlice';
import AdminkaOrdersPresent from './AdminkaOrdersPresent';

const AdminkaOrdersActive = () => {
  const { data: orders, isLoading } = useGetActiveOrdersQuery();

  return <AdminkaOrdersPresent title="Активные" isLoading={isLoading} orders={orders} />;
};

export default AdminkaOrdersActive;
