import { FC, useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import { hamburgerTime } from '../../utils/constants';
import HeaderTop from './HeaderTop';
import disableScroll from '../../utils/disable-scroll';
import { MobileMenuProps } from '../contracts';

export type WithHeaderOuterProps = {
  DesktopMenu?: React.ComponentType;
  MobileMenu: React.ComponentType<MobileMenuProps>;
};

const withHeader = ({ DesktopMenu, MobileMenu }: WithHeaderOuterProps): FC => {
  return () => {
    const isShowHamburger = useMediaQuery(hamburgerTime);
    const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);

    const onHamburgerClick = () => {
      setIsHamburgerOpen(!isHamburgerOpen);
      disableScroll();
    };

    useEffect(() => {
      if (isHamburgerOpen && isShowHamburger) {
        setIsHamburgerOpen(false);
      }
    }, [isHamburgerOpen, isShowHamburger]);

    return (
      <header className="header-wrapper">
        {isShowHamburger && <HeaderTop />}
        {isShowHamburger ? (
          DesktopMenu && <DesktopMenu />
        ) : (
          <MobileMenu isHamburgerOpen={isHamburgerOpen} onHamburgerClick={onHamburgerClick} />
        )}
        <div
          className={isHamburgerOpen ? 'overflow-open overflow' : 'overflow'}
          onClick={onHamburgerClick}
        ></div>
      </header>
    );
  };
};

export default withHeader;
