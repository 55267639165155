import withCarousel from '../carousel/WithCarousel';
import Slide from '../slide/Slide';
import newsCarouselConfig from './newsCarouselConfig';

import 'swiper/css/navigation';
import 'swiper/css/effect-fade';

import './swiper-buttons.scss';
import './swiper-slide.scss';

const NewsCarousel = withCarousel({
  config: newsCarouselConfig,
  Component: Slide,
  isIncludeSwperButtons: true,
});

export default NewsCarousel;
