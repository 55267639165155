import { useAppSelector } from '../../../../hooks';
import { selectedWiredMaxPage, selectedWiredTariffs } from '../../../../storeSlices/tariffSlice';
import AdminkaTariffsPresent from '../view/AdminkaTariffsPresent';

const AdminkaWiredTariffs = () => {
  const tariffs = useAppSelector(selectedWiredTariffs);
  const maxPage = useAppSelector(selectedWiredMaxPage);

  return <AdminkaTariffsPresent tariffs={tariffs} maxPage={maxPage} title="Кабельные тарифы" />;
};

export default AdminkaWiredTariffs;
