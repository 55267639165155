import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

type SpinnerProps = { open: boolean };

const Spinner = ({ open }: SpinnerProps) => {
  return (
    <div>
      <Backdrop
        data-testid="spinner"
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Spinner;
