import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useCallback, useState } from 'react';

import Dialog from '../../../../components/dialog';
import { useAppDispatch, useCurrentItems, useEnqueueMsg } from '../../../../hooks';
import { ITariff } from '../../../../storeSlices/tariffSlice/contracts.tariff';
import { tariffsPerPage } from '../../../../storeSlices/utils/constants';
import { calculateIndex } from '../../../../utils/calculateIndex';
import { tariffSuccessfullyDeleted } from '../../../../storeSlices/utils/successMessages.constants';
import {
  useArchiveTariffMutation,
  useDeleteTariffMutation,
} from '../../../../storeSlices/tariffSlice/query/tariffApiSlice';
import { removeTariff } from '../../../../storeSlices/tariffSlice/tariffStoreSlice';
import MyPagination from '../../../../components/pagination';
import BtnLink from '../../../../components/btnLink';
import DeleteBtn from '../../../../components/buttons/deleteBtn';
import { AddBtn, ArchiveBtn, EditBtn } from '../../../../components/buttons';

export enum ActionType {
  archive = 'archive',
  remove = 'remove',
}

const AdminkaTariffsPresent = ({
  tariffs,
  maxPage,
  isArchived,
  title,
}: {
  isArchived?: boolean;
  title: string;
  tariffs: ITariff[];
  maxPage: number;
}) => {
  const dispatch = useAppDispatch();

  const [deleteTariff] = useDeleteTariffMutation();
  const [archiveTariff] = useArchiveTariffMutation();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [action, setAction] = useState<ActionType>(ActionType.remove);
  const [selectedId, setSelectedId] = useState<null | string>(null);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const currentTariffs = useCurrentItems(currentPage, tariffsPerPage, tariffs);

  const handleDeleteClick = (id: string, act: ActionType) => {
    setSelectedId(id);
    setIsDialogOpen(!isDialogOpen);
    setAction(act);
  };

  const closeModalWindow = () => {
    setSelectedId(null);
    setIsDialogOpen(false);
  };

  const rmTariff = useCallback(async () => {
    if (selectedId) {
      const deletedTariff = currentTariffs.find((tariff) => tariff.id === selectedId);
      await deleteTariff(selectedId).unwrap();
      deletedTariff && dispatch(removeTariff(deletedTariff));
      closeModalWindow();
    }
  }, [currentTariffs, deleteTariff, dispatch, selectedId]);

  const arcTariff = useCallback(async () => {
    if (selectedId) {
      const archivedTariff = await archiveTariff(selectedId).unwrap();
      dispatch(removeTariff(archivedTariff));
      closeModalWindow();
    }
  }, [archiveTariff, dispatch, selectedId]);

  const onDialogOk = useEnqueueMsg({
    fn: action === ActionType.remove ? rmTariff : arcTariff,
    msg: tariffSuccessfullyDeleted,
    onError: closeModalWindow,
  });

  return (
    <>
      <Box sx={{ display: 'flex', mb: '5rem', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', columnGap: '1rem' }}>
          <BtnLink link="/adminka/tariffs" title="Проводные" />
          <BtnLink link="/adminka/tariffs/wireless" title="Беспроводные" />
          <BtnLink link="/adminka/tariffs/archived" title="Архив" />
        </Box>
        <AddBtn link="/adminka/tariffs/create" />
      </Box>

      <Typography textAlign="center" mb="3rem" component="h1" variant="h3">
        {title}
      </Typography>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: '20px', fontWeight: 500 }}>№</TableCell>
              <TableCell sx={{ fontSize: '20px', fontWeight: 500 }}>Название</TableCell>
              <TableCell sx={{ fontSize: '20px', fontWeight: 500 }} align="right">
                Скорость (MB)
              </TableCell>
              <TableCell sx={{ fontSize: '20px', fontWeight: 500 }} align="right">
                Цена
              </TableCell>
              {!isArchived && (
                <TableCell sx={{ fontSize: '20px', fontWeight: 500 }} align="center">
                  Действия
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {currentTariffs.map((tariff, index) => (
              <TableRow key={tariff.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell sx={{ fontSize: '18px' }} component="th" scope="row">
                  {calculateIndex({
                    itemsPerPage: tariffsPerPage,
                    index,
                    currentPage,
                  })}
                  )
                </TableCell>
                <TableCell sx={{ fontSize: '18px' }} component="th" scope="row">
                  {tariff.title}
                </TableCell>
                <TableCell sx={{ fontSize: '18px' }} align="right">
                  {tariff.speed}
                </TableCell>
                <TableCell sx={{ fontSize: '18px' }} align="right">
                  {tariff.cost}
                </TableCell>
                <TableCell align="right">
                  {!isArchived && (
                    <Box
                      sx={{
                        display: 'flex',
                        columnGap: '15px',
                        justifyContent: 'center',
                      }}
                    >
                      <EditBtn link={`/adminka/tariffs/edit/${tariff.id}`} />
                      <ArchiveBtn
                        onClick={() => handleDeleteClick(tariff.id, ActionType.archive)}
                      />
                      <DeleteBtn onClick={() => handleDeleteClick(tariff.id, ActionType.remove)} />
                    </Box>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog isOpen={isDialogOpen} onOk={onDialogOk} onCancel={closeModalWindow} />
      <MyPagination maxPage={maxPage} currentPage={currentPage} onChange={handlePaginationChange} />
    </>
  );
};

export default AdminkaTariffsPresent;
