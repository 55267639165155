import { createTheme } from '@mui/material';

const theme = createTheme();

theme.typography.h3 = {
  fontSize: '48px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '40px',
  },
};
export default theme;
