import { EffectFade, Keyboard } from 'swiper';
import { SwiperConfig } from '../carousel/contacts';

const newsCarouselConfig: SwiperConfig = {
  grabCursor: true,
  effect: 'fade',
  loop: true,
  slidesPerView: 1,
  speed: 700,
  keyboard: {
    enabled: true,
  },
  autoplay: {
    delay: 7000,
    disableOnInteraction: false,
  },
  className: 'newsSwiper',
  modules: [EffectFade, Keyboard],
};

export default newsCarouselConfig;
