import { FC, ReactNode } from 'react';
import { Box, Container } from '@mui/material';
import { useNavigate } from 'react-router';

import Hamburger from '../hamburger';
import HamburgerNavIcons from './HamburgerNavIcons';
import DaymohkLogo from '../../assets/images/logo.png';

import './with-mobile-menu.scss';

export type WithMobileMenuProps = {
  isHamburgerOpen: boolean;
  onHamburgerClick: () => void;
  children: ReactNode;
};

export const WithMobileMenu: FC<WithMobileMenuProps> = ({
  isHamburgerOpen,
  onHamburgerClick,
  children,
}) => {
  const navigate = useNavigate();
  return (
    <Container disableGutters>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            maxWidth: '65px',
            maxHeight: '65px',
            cursor: 'pointer',
            ml: '10px',
          }}
        >
          <img src={DaymohkLogo} alt="logo" onClick={() => navigate('/')} />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <HamburgerNavIcons />
          <Hamburger isHamburgerOpen={isHamburgerOpen} onHamburgerClick={onHamburgerClick} />
        </Box>
      </Box>
      <Box className={`mobile-menu ${isHamburgerOpen ? 'mobile-menu-open' : ''}`}>{children}</Box>
    </Container>
  );
};
