import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { Box, Button, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';

import { OrderFormData } from '../helpers/orderFormSchema';
import { orderFormSchemaUpdate } from './yupSchemaUpdate';
import { OrderFormPassport } from '../../../components/orderCommonForms';

export type OrderFormUpdateDate = Omit<OrderFormData, 'birthday' | 'passportDate'>;

export default function OrderFormUpdatePresent({
  defaultValues,
  onSubmit,
  title,
}: {
  defaultValues: OrderFormUpdateDate;
  onSubmit: (data: OrderFormData) => void;
  title: string;
}) {
  const navigate = useNavigate();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<OrderFormData>({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(orderFormSchemaUpdate),
  });

  const onSubmitWrapper = (data: OrderFormData) => {
    onSubmit(data);
    reset(defaultValues);
    navigate('/adminka/orders');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '3rem 1rem',
      }}
    >
      <Typography component="h1" variant="h2" sx={{ mb: '5rem' }}>
        {title}
      </Typography>
      <Box sx={{ mb: '4rem' }}>
        <Typography textAlign="center" component="h2" variant="h4" sx={{ mb: '2rem' }}>
          Изображения пасспорта
        </Typography>
        <img
          src={defaultValues.passportMainPagePhoto[0] as string}
          alt="Фото главной страницы паспорта"
        />
        <img
          src={defaultValues.passportRegistrationPhoto[0] as string}
          alt="Фото страницы регистрации паспорта"
        />
      </Box>
      <form onSubmit={handleSubmit(onSubmitWrapper)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '2rem',
            justifyContent: 'center',
          }}
        >
          <Typography component="h1" variant="h4">
            Паспортные данные
          </Typography>
          <OrderFormPassport control={control} errors={errors} register={register} />
          <Button
            variant="contained"
            type="submit"
            sx={{ width: '200px', mt: '2rem', alignSelf: 'center' }}
          >
            Сохранить
          </Button>
        </Box>
      </form>
    </Box>
  );
}
