import { green } from '@mui/material/colors';
import { SvgComponentProps } from './svgIconContracts';

const PhoneSvg = ({ fill = green[500] }: SvgComponentProps) => (
  <svg className="phone-svg" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.457 16.537c3.88 3.878 6.807 5.7 8.986 6.48 2.23.798 3.634.489 4.368.241.42-.141.747-.427.979-.675a5.78 5.78 0 0 0 .653-.872c.38-.606.703-1.292.922-1.797.415-.957.013-2.015-.825-2.518L18.5 14.973a2.059 2.059 0 0 0-2.515.31l-.754.753c-.774-.39-2.29-1.296-4.135-3.14-1.844-1.844-2.751-3.36-3.14-4.135l.754-.754a2.059 2.059 0 0 0 .31-2.515L6.592 1.45C6.091.612 5.033.21 4.076.625c-.505.22-1.191.543-1.797.922-.302.19-.61.408-.872.653-.248.232-.534.56-.675.979-.248.734-.557 2.139.241 4.368.78 2.18 2.602 5.107 6.48 8.986l.004.004Z"
      fill={fill}
    />
  </svg>
);

export default PhoneSvg;
