import { SvgComponentProps } from './svgIconContracts';

const MoreSvg = ({ fill = '#000', width = '30px', height = '30px' }: SvgComponentProps) => (
  <svg
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    width={height}
    height={width}
    viewBox="0 0 24 24"
  >
    <title />
    <path d="M8 12a2 2 0 1 1-2-2 2 2 0 0 1 2 2Zm10-2a2 2 0 1 0 2 2 2 2 0 0 0-2-2Zm-6 0a2 2 0 1 0 2 2 2 2 0 0 0-2-2Z" />
  </svg>
);
export default MoreSvg;
