import { useQuery } from 'react-query';
import { getAllTariffsArchived } from '../../../../api/tariff/tariffApi';
import { ITariff } from '../../../../storeSlices/tariffSlice/contracts.tariff';
import { tariffsPerPage } from '../../../../storeSlices/utils/constants';
import { getMaxPage } from '../../../../storeSlices/utils/getMaxpPage';
import Spinner from '../../../../components/spinner/Spinner';
import AdminkaTariffsPresent from '../view/AdminkaTariffsPresent';

const AdminkaArchivedTariffs = () => {
  const { data: tariffs, isLoading } = useQuery<ITariff[]>(
    'ARCHIVEDTARIFFS',
    getAllTariffsArchived
  );
  const maxPage = getMaxPage(tariffs?.length ?? 0, tariffsPerPage);
  return (
    <>
      {isLoading && <Spinner open={true} />}
      {tariffs && (
        <AdminkaTariffsPresent
          isArchived={true}
          tariffs={tariffs}
          maxPage={maxPage ?? 0}
          title="Архив"
        />
      )}
    </>
  );
};

export default AdminkaArchivedTariffs;
