export enum BreakpointsEnum {
  phone = 320,
  tablet = 720,
  pc = 1200,
}

export const breakpointsForMediaQuery = {
  phone: `(min-width:${BreakpointsEnum.phone}px)`,
  tablet: `(min-width:${BreakpointsEnum.tablet}px)`,
  pc: `(min-width:${BreakpointsEnum.pc}px)`,
};
