import { useGetCanceledOrdersQuery } from '../../../api/order/query/orderApiSlice';
import AdminkaOrdersPresent from './AdminkaOrdersPresent';

const AdminkaOrdersCanceled = () => {
  const { data: orders, isLoading } = useGetCanceledOrdersQuery();

  return <AdminkaOrdersPresent title="Отмененные" isLoading={isLoading} orders={orders} />;
};

export default AdminkaOrdersCanceled;
