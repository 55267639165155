import { useEffect, useState } from 'react';
import { SwiperConfig } from '../../components/carousel/contacts';
import useBreakpoint from '../useBreakpoint/useBreakpoint';

const useResponsibleSlidesPerView = (config: SwiperConfig, data: unknown[]): number => {
  const [slidesPerView, setSlidesPerView] = useState<number>(+config.slidesPerView);

  const breakpoint = useBreakpoint();

  useEffect(() => {
    const computeSlidesPerView = (dataLength: number, breakPointValue: number) =>
      dataLength < breakPointValue ? dataLength : breakPointValue;

    if (!!config.breakpoints) {
      const { breakpoints } = config;
      setSlidesPerView(computeSlidesPerView(breakpoints[breakpoint].slidesPerView, data.length));
    } else {
      if (data.length < slidesPerView) {
        setSlidesPerView(computeSlidesPerView(data.length, config.slidesPerView));
      }
    }
  }, [breakpoint, config, data.length, slidesPerView]);
  return slidesPerView;
};

export default useResponsibleSlidesPerView;
