import { Box, Typography } from '@mui/material';
import React from 'react';

import './feature.scss';

type FeatureProps = {
  title: string;
  feature: string;
  children: React.ReactNode;
};

const Feature = ({ title, feature, children }: FeatureProps) => {
  return (
    <Box
      className="aboute-us__feature"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box className="feature-icon-wrap">{children}</Box>
      <Typography component="h1" variant="h5" textAlign="center" maxWidth="300px" mb="1rem">
        {title}
      </Typography>
      <Typography component="p" variant="body1" textAlign="center">
        {feature}
      </Typography>
    </Box>
  );
};

export default Feature;
