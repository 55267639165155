import { FC } from 'react';
import { Link } from 'react-router-dom';
import { grey } from '@mui/material/colors';
import { Box, Button, Typography } from '@mui/material';

import { MobileMenuProps } from '../contracts';
import { WithMobileMenu } from '../withMobileMenu/WithMobileMenu';
import Navigation from '../navigation/Navigation';
import { PhoneSvg } from '../svgIcons';

import { mainNavigationList } from '../constants';

const MainMobileMenu: FC<MobileMenuProps> = ({ isHamburgerOpen, onHamburgerClick }) => {
  return (
    <WithMobileMenu isHamburgerOpen={isHamburgerOpen} onHamburgerClick={onHamburgerClick}>
      <>
        <Navigation listItems={mainNavigationList} navListItemClass="mobile-nav__item" />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mt: '1rem',
            mb: '1.5rem',
          }}
        >
          <Box sx={{ cursor: 'pointer' }}>
            <PhoneSvg fill={grey[500]} />
          </Box>
          <Box>
            <Typography component="p" variant="caption">
              Центр обслуживания абонентов:
            </Typography>
            <Typography component="a" variant="body1" href="tel:+7928-781-87-88">
              +7928-781-87-88
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button variant="contained" sx={{ paddingX: '30px', paddingY: '12px' }}>
            <Link to="/order">Подключиться</Link>
          </Button>
        </Box>
      </>
    </WithMobileMenu>
  );
};

export default MainMobileMenu;
