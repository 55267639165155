import { createTheme } from '@mui/material';

const theme = createTheme();

theme.typography.h4 = {
  fontSize: '2.125rem',
  [theme.breakpoints.between('sm', 'lg')]: {
    fontSize: '1.5rem',
  },
};
export default theme;
