import { FC } from 'react';
import { Button, ListItem } from '@mui/material';
import { blue } from '@mui/material/colors';
import { NavLink } from 'react-router-dom';

import './index.scss';

export type ListItemProps = {
  text: string;
  link: string;
  color?: string;
};

export const MyListItem: FC<ListItemProps> = ({ text, link, color = blue[500] }) => {
  return (
    <ListItem
      disablePadding
      className="adminka__link-item"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Button
        style={{ backgroundColor: 'transparent' }}
        sx={{
          paddingX: '1rem',
          width: '100%',
          color: color,
        }}
      >
        <NavLink to={link} color={color}>
          {text}
        </NavLink>
      </Button>
    </ListItem>
  );
};
