import { useSnackbar, VariantType } from 'notistack';
import { getErrorMsg } from '../../utils/getErrorMsg';

const useEnqueueMsgWithData = <T>({
  fn,
  msg = 'Успех',
  onError,
}: {
  fn: (data: T) => unknown | Promise<unknown>;
  msg?: string;
  onError?: () => void;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const enqueueSnackMessage = (message: string, variant: VariantType) => {
    enqueueSnackbar(message, { variant });
  };

  const wrappedFn = async (data: T) => {
    try {
      const result = await fn(data);
      enqueueSnackMessage(msg, 'success');
      return result;
    } catch (err) {
      enqueueSnackMessage(getErrorMsg(err), 'error');
      if (onError) {
        onError();
      }
    }
  };
  return wrappedFn;
};

export default useEnqueueMsgWithData;
