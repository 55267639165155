import { useAppSelector } from '../../../../hooks';
import {
  selectedWireLessMaxPage,
  selectedWireLessTariffs,
} from '../../../../storeSlices/tariffSlice';
import AdminkaTariffsPresent from '../view/AdminkaTariffsPresent';

const AdminkaWireLessTariffs = () => {
  const tariffs = useAppSelector(selectedWireLessTariffs);
  const maxPage = useAppSelector(selectedWireLessMaxPage);

  return <AdminkaTariffsPresent tariffs={tariffs} maxPage={maxPage} title="Беспроводные тарифы" />;
};

export default AdminkaWireLessTariffs;
