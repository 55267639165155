const tariffSuccessfullyCreated = 'Тарифф успешно создан';
const tariffSuccessfullyUpdated = 'Тарифф успешно обновлен';
const tariffSuccessfullyDeleted = 'Тарифф успешно удален';
const newsSuccessfullyCreated = 'Новость успешно создана';
const newsSuccessfullyUpdated = 'Новость успешно обновлена';
const newsSuccessfullyDeleted = 'Новость успешно удалена';

export {
  newsSuccessfullyCreated,
  newsSuccessfullyDeleted,
  newsSuccessfullyUpdated,
  tariffSuccessfullyCreated,
  tariffSuccessfullyDeleted,
  tariffSuccessfullyUpdated,
};
