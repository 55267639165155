const GoalSvg = () => (
  <svg
    className="feature-svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
  >
    <path d="M512 76.228 435.915.143 330.168 105.891c-33.73-20.164-72.115-30.766-111.8-30.766C97.959 75.123 0 173.083 0 293.49c0 58.33 22.714 113.166 63.956 154.41 41.243 41.244 96.08 63.956 154.41 63.956s113.168-22.714 154.41-63.956 63.956-96.081 63.956-154.41c0-39.533-10.528-77.796-30.548-111.448L512 76.228zm-76.085-31.836 31.836 31.836-19.744 19.744-31.836-31.836 19.744-19.744zM394.046 86.26l31.836 31.836-122.201 122.2-31.432-32.24L394.046 86.26zM269.485 291.808c.017.559.033 1.119.033 1.682 0 28.206-22.946 51.152-51.152 51.152s-51.152-22.946-51.152-51.152 22.946-51.152 51.152-51.152c.686 0 1.372.016 2.055.043l-21.839 71.181 70.903-21.754zm-23.688-25.462 9.475-30.884 20.996 21.535-30.471 9.349zm-13.54-62.545-2.47 8.051a82.832 82.832 0 0 0-11.42-.802c-45.458 0-82.441 36.983-82.441 82.441 0 45.457 36.982 82.441 82.441 82.441s82.441-36.983 82.441-82.441c0-3.723-.259-7.415-.749-11.063l8.284-2.542 25.352-25.352a122.13 122.13 0 0 1 6.403 38.957c0 67.123-54.608 121.731-121.731 121.731S96.635 360.613 96.635 293.49s54.608-121.731 121.731-121.731c13.449 0 26.778 2.243 39.386 6.546l-25.495 25.496zm-13.89 276.767c-103.155 0-187.078-83.923-187.078-187.078s83.923-187.078 187.078-187.078c31.323 0 61.69 7.707 88.86 22.418l-25.413 25.413a153.258 153.258 0 0 0-63.449-13.772c-84.376 0-153.02 68.645-153.02 153.02s68.645 153.02 153.02 153.02 153.02-68.645 153.02-153.02c0-21.779-4.686-43.345-13.596-63.052l25.441-25.441c14.577 27.082 22.214 57.321 22.214 88.492.001 103.155-83.922 187.078-187.077 187.078z" />
  </svg>
);

export default GoalSvg;
