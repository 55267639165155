import { useNavigate } from 'react-router';
import { Box, Button, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Input } from '../../../../../components/customInputs/Input';
import { FileInput } from '../../../../../components/customInputs/FileInput';
import schema from '../yupSchema';
import { NewsFormData } from '../newsForm.contracts';

type NewsFormPresentProps = {
  title: string;
  defaultValues: NewsFormData;
  onSubmit: (data: NewsFormData) => void;
};

export default function NewsFormPresent({ title, defaultValues, onSubmit }: NewsFormPresentProps) {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<NewsFormData>({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();

  const onSubmitWrapper = (data: NewsFormData) => {
    onSubmit(data);
    reset(defaultValues);
    navigate('/adminka/news', { replace: true });
  };

  return (
    <Box>
      <Typography component="h1" variant="h4" sx={{ mb: '2rem', textAlign: 'center' }}>
        {title}
      </Typography>
      <form onSubmit={handleSubmit(onSubmitWrapper)} encType="multipart/form-data">
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ mb: '1rem' }}>
            <Input
              {...register('title')}
              label="Название"
              placeholder="Введите название..."
              error={!!errors.title}
              helperText={errors?.title?.message}
            />
          </Box>
          <Input
            {...register('content')}
            label="Содержание"
            placeholder="Введите содержание..."
            rows={15}
            error={!!errors.content}
            helperText={errors?.content?.message}
          />
          <Box sx={{ mt: '1rem' }}>
            <FileInput<NewsFormData>
              name="files"
              control={control}
              error={!!errors.files}
              helperText={errors.files?.message}
            />
          </Box>
          <Button
            variant="contained"
            type="submit"
            sx={{ width: '200px', mt: '2rem', alignSelf: 'center' }}
          >
            Сохранить
          </Button>
        </Box>
      </form>
    </Box>
  );
}
