import './hamburger.scss';

export type HamburgerState = {
  isHamburgerOpen: boolean;
  onHamburgerClick: () => void;
};

const Hamburger = ({ isHamburgerOpen, onHamburgerClick }: HamburgerState) => {
  const classes = `nav-toggle ${isHamburgerOpen ? 'hamburger-open' : ''}`;

  return (
    <button
      title="hamburger"
      className={classes}
      onClick={() => {
        onHamburgerClick();
      }}
    >
      <span className="hamburger"></span>
    </button>
  );
};

export default Hamburger;
