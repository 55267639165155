import toBase64 from '../../../utils/toBase64';
import { useGetOrderQuery, useUpdateOrderMutation } from '../../../api/order/query/orderApiSlice';
import { useCallback, useMemo } from 'react';
import useEnqueueMsgWithData from '../../../hooks/useEnqueueMsg/useEnqueueMsgWithData';
import OrderFormUpdatePresent, { OrderFormUpdateDate } from './OrderFormUpdatePresent';
import { useParams } from 'react-router';
import Spinner from '../../../components/spinner/Spinner';
import { getDefaultDate } from '../../../utils/getDefaultDate';
import { OrderFormData } from '../helpers/orderFormSchema';

export default function OrderFormUpdate() {
  const { orderId } = useParams();

  const { data: order, isLoading } = useGetOrderQuery(orderId as string);

  const defaultValues: OrderFormUpdateDate | undefined = useMemo(() => {
    if (order) {
      const { id, status, ...values } = order;
      return {
        ...values,
        passportMainPagePhoto: [values.passportMainPagePhoto],
        passportRegistrationPhoto: [values.passportRegistrationPhoto],
        passportDate: getDefaultDate(values.passportDate),
        birthday: getDefaultDate(values.birthday),
        aggreddToShareDate: false,
      };
    }
  }, [order]);

  const [updateOrder] = useUpdateOrderMutation();

  const editOrder = useCallback(
    async ({ aggreddToShareDate, ...createOrderDto }: OrderFormData) => {
      const mainPageHash = await toBase64(createOrderDto.passportMainPagePhoto[0]);
      const registrationHash = await toBase64(createOrderDto.passportMainPagePhoto[0]);

      await updateOrder({
        updateOrderDto: {
          ...createOrderDto,
          passportMainPagePhoto: mainPageHash,
          passportRegistrationPhoto: registrationHash,
          passportDate: createOrderDto?.passportDate.toISOString(),
          birthday: createOrderDto?.birthday.toISOString(),
        },
        id: orderId as string,
      }).unwrap();
    },
    [orderId, updateOrder]
  );

  const onSubmit = useEnqueueMsgWithData<OrderFormData>({
    fn: editOrder,
    msg: 'Заказ создан',
  });

  return (
    <>
      {isLoading && <Spinner open={true} />}
      {defaultValues && (
        <OrderFormUpdatePresent
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          title="Изменить заказ"
        />
      )}
    </>
  );
}
