import { Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router';
import { tabletQuery } from '../../utils/constants';

import './nav-item.scss';
import './navigation-item-animation.scss';

export type NavigationItemProps = {
  children?: React.ReactNode;
  title: string;
  classes?: string;
  link?: string;
};

const NavigationItem = ({ children, title, classes, link }: NavigationItemProps) => {
  const navigate = useNavigate();
  const onClickHandle = () => {
    !!link && navigate(link);
  };
  const showTablet = useMediaQuery(tabletQuery);

  return (
    <div onClick={onClickHandle} className={classes}>
      {!!children && children}
      <Typography
        variant={showTablet ? 'body1' : 'body2'}
        component="p"
        className="nav-item__title"
      >
        {title}
      </Typography>
    </div>
  );
};

export default NavigationItem;
