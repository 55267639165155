import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store/store.contracts';
import { clearLocalStore, getLocalStoreValue, setLocalStoreItem } from '../../utils/localStore';
import {
  clearSessionStore,
  getSessionStoreValue,
  setSessionStoreItem,
} from '../../utils/sessionStore';
import isAuthTokens from './AuthTokensGuard';
import { loginThunk } from './thunks/loginThunk';
import { refreshThunk } from './thunks/refreshThunk';

export const getEmptyTokens = () => ({
  accessToken: '',
  refreshToken: '',
});

export type AuthTokens = ReturnType<typeof getEmptyTokens>;
export type AuthStoreInitState = {
  tokens: AuthTokens | null;
  isLoading: boolean;
};

const authStoreInitialState: AuthStoreInitState = {
  tokens: null,
  isLoading: false,
};

const authStoreSlice = createSlice({
  name: 'auth',
  initialState: authStoreInitialState,
  reducers: {
    checkStorage: (state) => {
      const tokens =
        getLocalStoreValue('tokens', isAuthTokens) ?? getSessionStoreValue('tokens', isAuthTokens);
      state.tokens = tokens;
    },
    setTokens: (state, { payload }: PayloadAction<AuthTokens>) => {
      state.tokens = payload;
      const tokens = getLocalStoreValue('tokens', isAuthTokens);
      if (tokens) {
        setLocalStoreItem('tokens', payload);
      } else {
        setSessionStoreItem('tokens', payload);
      }
    },
    auth: (
      state,
      { payload: { tokens, remember } }: PayloadAction<{ tokens: AuthTokens; remember: boolean }>
    ) => {
      state.tokens = tokens;
      if (remember) {
        setLocalStoreItem('tokens', tokens);
      } else {
        setSessionStoreItem('tokens', tokens);
      }
    },
    resetTokens: (state) => {
      state.tokens = null;
      clearLocalStore();
      clearSessionStore();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(loginThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.tokens = payload;
      setLocalStoreItem('tokens', payload);
    });
    builder.addCase(loginThunk.rejected, (state) => {
      state.isLoading = false;
      state.tokens = null;
      clearLocalStore();
    });
    builder.addCase(refreshThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(refreshThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.tokens = payload;
      setLocalStoreItem('tokens', payload);
    });
    builder.addCase(refreshThunk.rejected, (state) => {
      state.isLoading = false;
      state.tokens = null;
      clearLocalStore();
    });
  },
});

export const selectedTokens = (state: RootState) => state.auth.tokens;
export const selectedTokensAreLoading = (state: RootState) => state.auth.isLoading;
export const { checkStorage, setTokens, resetTokens, auth } = authStoreSlice.actions;
export default authStoreSlice.reducer;
