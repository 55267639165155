import { baseAxios as axios } from '../axios/axios';
import { AuthTokens } from '../../storeSlices/authSLice/authStoreSlice';

const enpoint = 'auth/login/';

export type LoginDto = { login: string; password: string };

export const login = async (data: LoginDto) => {
  const result = await axios.post<AuthTokens>(enpoint, data);
  return result.data;
};
