import { Box, Button, Container } from '@mui/material';
import { useCallback, useState } from 'react';

import Spinner from '../../../components/spinner';
import Dialog from '../../../components/dialog';
import { OrderStatusEnum } from '../../../api/order/orderApi';
import { useNavigate, useParams } from 'react-router';
import { useEnqueueMsg } from '../../../hooks';
import {
  useGetOrderQuery,
  useUpdateOrderStatusMutation,
} from '../../../api/order/query/orderApiSlice';
import Order from './Order';

const OrderContainer = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { isLoading, data: order } = useGetOrderQuery(id as string);
  const isActive = order?.status === OrderStatusEnum.active;

  const [updateOrderStatus] = useUpdateOrderStatusMutation();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [status, setStatus] = useState<'archive' | 'cancel' | null>(null);

  const showDialogWindow = (mode: 'archive' | 'cancel' | null) => {
    setStatus(mode);
    setIsDialogOpen(!isDialogOpen);
  };

  const closeDialogWindow = () => {
    setIsDialogOpen(false);
    setStatus(null);
  };

  const updOrder = useCallback(async () => {
    if (id && status) {
      await updateOrderStatus({ id, status }).unwrap();
      closeDialogWindow();
      navigate('/adminka/orders', { replace: true });
    }
  }, [id, navigate, status, updateOrderStatus]);

  const onDialogOk = useEnqueueMsg({
    fn: updOrder,
    msg: 'Статус заказа обновлен',
    onError: closeDialogWindow,
  });

  return (
    <>
      {isLoading ? (
        <Spinner open={true} />
      ) : (
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: '3rem',
            paddingBottom: '5rem',
          }}
        >
          {order && <Order order={order} />}
          {isActive && (
            <Box sx={{ display: 'flex', columnGap: '1rem', mt: '5rem' }}>
              <Button onClick={() => showDialogWindow('cancel')} variant="outlined" color="error">
                Откланить
              </Button>
              <Button
                variant="outlined"
                color="success"
                onClick={() => showDialogWindow('archive')}
              >
                Обработать
              </Button>
            </Box>
          )}
          <Dialog isOpen={isDialogOpen} onOk={onDialogOk} onCancel={closeDialogWindow} />
        </Container>
      )}
    </>
  );
};

export default OrderContainer;
