import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosInstance } from 'axios';
import { createTariff } from '../../../api/tariff/tariffApi';
import { CreateTariffDto } from '../contracts.tariff';

const createTariffThunk = createAsyncThunk(
  'tariff/createTariffThunk',
  async ({
    axios,
    createTariffDto,
  }: {
    createTariffDto: CreateTariffDto;
    axios: AxiosInstance;
  }) => {
    const tariff = await createTariff(axios, createTariffDto);
    return tariff;
  }
);

export default createTariffThunk;
