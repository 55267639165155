import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { PhoneSvg, UserSvg } from '../svgIcons';
import config from '../../config/config';

const HamburgerNavIcons = () => {
  return (
    <Box className="hamburger-menu__icons-wrapper" sx={{ mr: '1rem', display: 'flex' }}>
      <PhoneSvg width="20px" height="20px" />
      <Link to={config.lkLink}>
        <UserSvg width="25px" height="25px" />
      </Link>
    </Box>
  );
};

export default HamburgerNavIcons;
