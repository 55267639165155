import { useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { BreakpointsEnum, breakpointsForMediaQuery } from '../../utils/breakpoints';

const useBreakpoint = (): BreakpointsEnum => {
  const [breakpoint, setBreakpoint] = useState<BreakpointsEnum>(1200);

  const phoneBreakpoint = useMediaQuery(breakpointsForMediaQuery.phone);
  const tabletBreakpoint = useMediaQuery(breakpointsForMediaQuery.tablet);
  const pcBreakpoint = useMediaQuery(breakpointsForMediaQuery.pc);

  useEffect(() => {
    if (phoneBreakpoint && !tabletBreakpoint) {
      setBreakpoint(BreakpointsEnum.phone);
    } else if (tabletBreakpoint && !pcBreakpoint) {
      setBreakpoint(BreakpointsEnum.tablet);
    } else {
      setBreakpoint(BreakpointsEnum.pc);
    }
  }, [pcBreakpoint, phoneBreakpoint, tabletBreakpoint]);
  return breakpoint;
};

export default useBreakpoint;
