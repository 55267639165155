import safeJsonParse from './safeJsonParse';

export const getLocalStoreValue = <T>(key: string, guard: (o: unknown) => o is T) => {
  const item = localStorage.getItem(key);

  if (item) {
    const parsedItem = safeJsonParse<T>(guard)(item);
    return parsedItem;
  }
  return null;
};

export const setLocalStoreItem = <T>(key: string, item: T) => {
  localStorage.setItem(key, JSON.stringify(item));
};

export const clearLocalStore = () => localStorage.clear();
