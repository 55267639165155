import { List } from '@mui/material';

import NavigationItem, { NavigationItemProps } from './NavigationItem';

import './navList.scss';

export type NavigationListItems = NavigationItemProps[];

type NavigationProps = {
  navListItemClass?: string;
  listItems: NavigationListItems;
};

const Navigation = ({
  navListItemClass: classes = 'desktop-nav__item',
  listItems,
}: NavigationProps) => {
  const isDesktopNavList = classes === 'desktop-nav__item';
  return (
    <List className={isDesktopNavList ? 'desktop-nav' : ''}>
      {listItems.map((listItem) => (
        <NavigationItem
          key={listItem.title}
          classes={`${classes} ${listItem.classes ?? ''}`}
          title={listItem.title}
          link={listItem.link}
        >
          {listItem.children}
        </NavigationItem>
      ))}
    </List>
  );
};

export default Navigation;
