import { apiSlice, ApiTagTypes } from '../../apiSlice/apiSlice';
import { CreateOrderDto, IOrder, UpdateOrderDto } from '../orderApi';

export const orderApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrders: builder.query<IOrder[], string>({
      query: (url) => 'order/' + url,
      providesTags: [ApiTagTypes.order],
    }),
    getActiveOrders: builder.query<IOrder[], void>({
      query: () => 'order/active',
      providesTags: [ApiTagTypes.order],
    }),
    getArchivedOrders: builder.query<IOrder[], void>({
      query: () => 'order/archived',
      providesTags: [ApiTagTypes.order],
    }),
    getCanceledOrders: builder.query<IOrder[], void>({
      query: () => 'order/canceled',
      providesTags: [ApiTagTypes.order],
    }),
    getOrder: builder.query<IOrder, string>({
      query: (id) => 'order/' + id,
      providesTags: [ApiTagTypes.order],
    }),
    createOrder: builder.mutation<IOrder, CreateOrderDto>({
      query: (createOrderDto) => ({
        url: 'order/',
        method: 'POST',
        body: { ...createOrderDto },
      }),
      invalidatesTags: [ApiTagTypes.order],
    }),
    updateOrder: builder.mutation<IOrder, { updateOrderDto: UpdateOrderDto; id: string }>({
      query: ({ updateOrderDto, id }) => ({
        url: 'order/' + id,
        method: 'PUT',
        body: { ...updateOrderDto },
      }),
      invalidatesTags: [ApiTagTypes.order],
    }),
    updateOrderStatus: builder.mutation<IOrder, { id: string; status: 'archive' | 'cancel' }>({
      query: ({ id, status }) => ({
        url: `order/${id}/${status}`,
        method: 'PUT',
      }),
      invalidatesTags: [ApiTagTypes.order],
    }),
    deleteOrder: builder.mutation<void, string>({
      query: (id) => ({
        url: 'order/' + id,
        method: 'DELETE',
      }),
      invalidatesTags: [ApiTagTypes.order],
    }),
  }),
});

export const {
  useGetOrdersQuery,
  useCreateOrderMutation,
  useUpdateOrderMutation,
  useDeleteOrderMutation,
  useUpdateOrderStatusMutation,
  useGetOrderQuery,
  useGetActiveOrdersQuery,
  useGetArchivedOrdersQuery,
  useGetCanceledOrdersQuery,
} = orderApiSlice;
