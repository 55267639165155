const loremImp = 'Lorem Ipsum is a BEAST.';

const containerWidth = '1180px';
const unknownErrorMessage = 'Something went wrong';
const requiredFieldMessage = 'Обязательное поле';
const dateErrorMsg = 'Введите валидную дату: гггг-мм-дд';
const hamburgerTime = '(min-width:700px)';
const tabletQuery = '(min-width:850px)';

export {
  containerWidth,
  dateErrorMsg,
  hamburgerTime,
  loremImp,
  requiredFieldMessage,
  tabletQuery,
  unknownErrorMessage,
};
