import { TariffFormData } from '../yupSchema';
import { formatTariffFormData, getTariffFormDefaultValues } from '../utils';
import { useAppDispatch } from '../../../../../hooks';

import { useCreateTariffMutation } from '../../../../../storeSlices/tariffSlice/query/tariffApiSlice';
import { addTariff } from '../../../../../storeSlices/tariffSlice/tariffStoreSlice';
import { useCallback } from 'react';
import useEnqueueMsgWithData from '../../../../../hooks/useEnqueueMsg/useEnqueueMsgWithData';
import TariffFormPresent from '../view/TariffFormPresent';

export default function TariffFormCreate() {
  const defaultValues = getTariffFormDefaultValues(null);

  const dispath = useAppDispatch();

  const [createTariffMutation] = useCreateTariffMutation();

  const generateTariff = useCallback(
    async (data: TariffFormData) => {
      const formattedData = formatTariffFormData(data);
      const newTariff = await createTariffMutation(formattedData).unwrap();
      dispath(addTariff(newTariff));
    },
    [createTariffMutation, dispath]
  );

  const onSubmit = useEnqueueMsgWithData<TariffFormData>({
    fn: generateTariff,
    msg: 'Тарифф успешно создан',
  });

  return (
    <TariffFormPresent onSubmit={onSubmit} defaultValues={defaultValues} title="Cоздать тарифф" />
  );
}
