import { AuthTokens } from './authStoreSlice';

const authKeys = ['accessToken', 'refreshToken'];

const isAuthTokens = (tokens: unknown): tokens is AuthTokens => {
  if (tokens instanceof Object) {
    return authKeys.every((key) => tokens.hasOwnProperty(key));
  }
  return false;
};

export default isAuthTokens;
