import { apiSlice, ApiTagTypes } from '../../../api/apiSlice/apiSlice';
import { CreateNewsDto, UpdateNewsDto } from '../../../view/adminka/news/main/contracts.news';
import { INews } from '../contracts.news';

export const newsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createNews: builder.mutation<INews, CreateNewsDto>({
      query: (formData) => ({
        url: 'news/',
        method: 'POST',
        body: formData,
        providesTags: [ApiTagTypes.news],
      }),
    }),
    updateNews: builder.mutation<INews, { dto: UpdateNewsDto; id: string }>({
      query: ({ id, dto: formData }) => ({
        url: `news/${id}`,
        method: 'PUT',
        body: formData,
      }),
      invalidatesTags: [ApiTagTypes.news],
    }),
    deleteNews: builder.mutation<void, string>({
      query: (id) => ({
        url: `news/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [ApiTagTypes.news],
    }),
  }),
});

export const { useCreateNewsMutation, useUpdateNewsMutation, useDeleteNewsMutation } = newsApiSlice;
