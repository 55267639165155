import axios from 'axios';
import config from '../../config/config';

const baseAxios = axios.create({ baseURL: config.apiBaseUrl });

const privateAxios = axios.create({
  baseURL: config.apiBaseUrl,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: false,
});

export { baseAxios, privateAxios };
