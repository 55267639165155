import { useNavigate } from 'react-router';
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import { green } from '@mui/material/colors';

import { ITariff } from '../../storeSlices/tariffSlice/contracts.tariff';
import tariffTheme from './tariffTheme';
import withTheme from '../withTheme';

import { getTariffTypeInRussian } from './getTariffTypeInRussian';

import WiredSvg from '../svgIcons/WiredSvg';
import WirelessSvg from '../svgIcons/WireLessSvg';

import './circle.scss';
import './styles.scss';

export type TariffContentProps = { tariff: ITariff };
const TariffSimple = ({ tariff }: TariffContentProps) => {
  const navigate = useNavigate();
  const tariffType = getTariffTypeInRussian(tariff.type);

  const Icon = tariff.type === 'wireLess' ? WirelessSvg : WiredSvg;

  const queryTablet = useMediaQuery('(max-width:1070px)');
  const queryPhone = useMediaQuery('(max-width:890px)');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: queryPhone ? 'column' : 'row',
        alignItems: queryPhone ? 'center' : 'baseline',
        justifyContent: 'space-between',
        mb: '1rem',
      }}
    >
      <Typography
        component="h1"
        variant="h4"
        textAlign="center"
        className="tariff-simple"
        sx={{ width: queryTablet ? '180px' : '250px' }}
      >
        {tariff.title}
      </Typography>
      <Typography component="p" variant="h5">
        до {tariff.speed} Mbps
      </Typography>
      <Typography component="p" variant="h5" color={green[400]}>
        {tariff.cost} &#8381; / мес.
      </Typography>
      <Typography component="p" variant="h5">
        {queryTablet ? <Icon /> : tariffType}
      </Typography>
      <Button
        variant="contained"
        className="tariff-box"
        sx={{ color: '#ffffff', width: queryTablet ? '200px' : '300px' }}
        onClick={() => navigate(`/order/create/${tariff.id}`)}
      >
        <Typography component="p" variant="h5">
          Подключить
        </Typography>
      </Button>
    </Box>
  );
};

export default withTheme<TariffContentProps>({
  theme: tariffTheme,
  Component: TariffSimple,
});
