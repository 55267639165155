import { Button } from '@mui/material';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

type BtnLinkProps = {
  title?: string;
  link: string;
  icon?: ReactNode;
};

const BtnLink = ({ link, title, icon }: BtnLinkProps) => {
  const navigate = useNavigate();
  return (
    <Button variant="outlined" onClick={() => navigate(link)}>
      {icon ?? title}
    </Button>
  );
};

export default BtnLink;
