import { useGetArchivedOrdersQuery } from '../../../api/order/query/orderApiSlice';
import AdminkaOrdersPresent from './AdminkaOrdersPresent';

const AdminkaOrdersArchived = () => {
  const { data: orders, isLoading } = useGetArchivedOrdersQuery();

  return <AdminkaOrdersPresent title="Архив" isLoading={isLoading} orders={orders} />;
};

export default AdminkaOrdersArchived;
