import { Box, Container, Typography } from '@mui/material';
import { IProfile } from '../../api/profile/profile.contracts';
import { FC } from 'react';

type ProfileProps = { profile: IProfile };
const ProfilePresent: FC<ProfileProps> = ({ profile }) => {
  return (
    <Container>
      <Typography variant="h2" component="h1">
        Личный кабинет пользователя
      </Typography>
      <Box className="profile__personal-data">
        <Typography component="p" variant="h5">
          ФИО: {profile.cl_con_client_fio}
        </Typography>
        <Typography component="p" variant="h5">
          Баланс: {profile.cl_con_client_balans}
        </Typography>
        <Typography component="p" variant="h5">
          Адрес: {profile.cl_con_adres}
        </Typography>
        <Typography component="p" variant="h5">
          Тариф: {profile.cl_con_tarif_name}
        </Typography>
        <Typography component="p" variant="h5">
          Статус: {profile.cl_con_clients_NAME}
        </Typography>
        <Typography component="p" variant="h5">
          Цена: {profile.cl_con_tarif_cost}
        </Typography>
      </Box>
    </Container>
  );
};

export default ProfilePresent;
