import * as yup from 'yup';
import { InferType } from 'yup';
import { TariffTypeOption } from '../../../../storeSlices/tariffSlice/contracts.tariff';
import { requiredFieldMessage } from '../../../../utils/constants';

export const tariffTypeOptions: TariffTypeOption[] = ['wired', 'wireLess'];
const schema = yup.object().shape({
  title: yup
    .string()
    .required(requiredFieldMessage)
    .min(2, 'Название должно быть больше 2 символов'),
  speed: yup
    .string()
    .required(requiredFieldMessage)
    .matches(/^[0-9]+$/, 'Только цифры')
    .min(1, 'Больше чем одна цифрa'),
  cost: yup
    .string()
    .required(requiredFieldMessage)
    .matches(/^[0-9]+$/, 'Только цифры')
    .min(3, 'Больше чем три цифры'),
  type: yup.string().required(requiredFieldMessage).oneOf(tariffTypeOptions),
});

export type TariffFormData = InferType<typeof schema>;
export type TariffFormDataOption = keyof TariffFormData;

export default schema;
