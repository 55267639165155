import { useEffect, useState } from 'react';

const useCurrentItems = <T>(currentPage: number, itemsPerPage: number, items: T[]) => {
  const [currentItems, setCurrentItems] = useState<T[]>([]);

  useEffect(() => {
    if (items instanceof Array && items.length) {
      const indexOfLastItem = currentPage * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      setCurrentItems(items.slice(indexOfFirstItem, indexOfLastItem));
    }
  }, [currentPage, items, itemsPerPage]);

  return currentItems;
};

export default useCurrentItems;
