import * as yup from 'yup';
import { checkFilesFormat, checkFilesSize } from '../../../../utils/checkFiles';

export const fileFormats = ['image/jpg', 'image/jpeg', 'image/png'];
export const FILE_SIZE = 10000 * 1024;

const schema = yup.object().shape({
  content: yup.string().required().min(3, 'Содержание должно быть больше 3 символов'),
  title: yup.string().required().min(2, 'Название должно быть больше 2 символов'),
  files: yup
    .mixed<File[]>()
    .required()
    .test('length', 'Выберите одно фото', (values) => values?.length === 1)
    .test('fileFormat', 'Неподдерживаемый формат', (files: File[]) =>
      checkFilesFormat(files, fileFormats)
    )
    .test('fileSize', 'Размер файла слишкмо большой', (files: File[]) =>
      checkFilesSize(files, FILE_SIZE)
    ),
});

export default schema;
