import Compressor from 'compressorjs';
import { CreateNewsDto } from '../../../view/adminka/news/main/contracts.news';
import toBase64 from '../../../utils/toBase64';

type PropperCreateNewsDto = Omit<CreateNewsDto, 'backgroundImage'> & {
  backgroundImage: string;
};

const isImageString = async (dto: CreateNewsDto): Promise<PropperCreateNewsDto | null> => {
  return new Promise((resolve, reject) => {
    if (dto.backgroundImage instanceof File) {
      new Compressor(dto.backgroundImage, {
        quality: 0.7,
        success(file) {
          toBase64(file).then((hash) => {
            if (hash && typeof hash === 'string') {
              resolve({ ...dto, backgroundImage: hash });
            }
          });
        },
      });
    } else if (typeof dto.backgroundImage === 'string') {
      return resolve(dto as PropperCreateNewsDto);
    } else {
      reject(null);
    }
  });
};

export default isImageString;
