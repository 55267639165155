import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/dist/query/react';

import { Mutex } from 'async-mutex';
import config from '../../config/config';
import { RootState } from '../../store/store.contracts';
import { AuthTokens, resetTokens, setTokens } from '../../storeSlices/authSLice/authStoreSlice';

const baseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = fetchBaseQuery({
  baseUrl: config.apiBaseUrl,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.tokens;

    if (token) {
      headers.set('authorization', `Bearer ${token.accessToken}`);
    }

    return headers;
  },
});

// create a new mutex
const mutex = new Mutex();
const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();

  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        // try to get a new token
        const token = (api.getState() as RootState).auth.tokens?.refreshToken;
        const refreshResult = await baseQuery(
          {
            url: 'auth/refresh',
            method: 'POST',
            body: { refreshToken: token },
          },
          api,
          extraOptions
        );
        if (refreshResult.data) {
          // store the new token
          api.dispatch(setTokens(refreshResult.data as AuthTokens));
          // retry the initial query
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(resetTokens());
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export enum ApiTagTypes {
  district = 'DISTRICT',
  news = 'NEWS',
  tariff = 'TARIFF',
  order = 'ORDER',
  user = 'USER',
}

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
  refetchOnMountOrArgChange: true,
  tagTypes: [
    ApiTagTypes.district,
    ApiTagTypes.news,
    ApiTagTypes.user,
    ApiTagTypes.order,
    ApiTagTypes.tariff,
  ],
});
