import { Box, Button, Container, TextField, Typography, useMediaQuery } from '@mui/material';
import { teal } from '@mui/material/colors';

import theme from './formBoxTheme';
import withTheme from '../withTheme';

const FormBox = () => {
  const matches = useMediaQuery('(min-width:600px)');

  return (
    <Box sx={{ backgroundColor: 'rgb(171 209 246 / 50%)', paddingY: '2rem' }}>
      <Container>
        <Box sx={{ marginX: 'auto', textAlign: 'center' }}>
          <Typography component="h1" variant="h3" textAlign="center" mb="1rem" color={teal[600]}>
            Подключайся прямо сейчас
          </Typography>
          <Typography
            component="p"
            variant="body1"
            color={teal[300]}
            sx={{
              marginX: 'auto',
              textAlign: 'center',
              mb: '4rem',
              maxWidth: '600px',
              fontWeight: 500,
            }}
          >
            Подключите качественный интернет на высокой скорости + ТВ Заполните форму, наши
            менеджеры Вам перезвонят
          </Typography>
        </Box>
        <Box>
          <Box
            display="flex"
            sx={{
              gap: '2rem',
              flexDirection: matches ? 'row' : 'column',
              mb: '2rem',
            }}
          >
            <TextField id="form-box__name" label="Ваше имя" variant="outlined" fullWidth />
            <TextField id="form-box__number" label="Ваш номер" variant="outlined" fullWidth />
          </Box>
          <TextField
            id="form-box__address"
            label="Ваш адрес"
            variant="outlined"
            fullWidth
            sx={{ mb: '3rem' }}
          />
          <Box sx={{ marginX: 'auto', maxWidth: '400px' }}>
            <Button variant="contained" sx={{ width: '100%', fontSize: '24px' }}>
              <Typography component="p" variant="button">
                Создать заявку
              </Typography>
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default withTheme({ theme, Component: FormBox });
