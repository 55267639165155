import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export default function DialogWindow({
  isOpen,
  onOk,
  onCancel,
}: {
  isOpen: boolean;
  onOk: () => void;
  onCancel: () => void;
}) {
  const handleOk = () => {
    onOk();
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <div>
      <Dialog open={isOpen} onClose={handleCancel}>
        <DialogTitle style={{ cursor: 'default' }}>Вы уверены что хотите продолжить?</DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button variant="outlined" color="warning" onClick={handleCancel}>
            Отмена
          </Button>
          <Button color="success" variant="outlined" onClick={handleOk}>
            Да
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
