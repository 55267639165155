import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Typography } from '@mui/material';

import { Input } from '../../../../components/customInputs/Input';
import schema, { DistrictFormData } from './yupSchema';
import { useNavigate } from 'react-router';

type DistrictFormPresentProps = {
  title: string;
  defaultValues: DistrictFormData;
  values?: DistrictFormData;
  onSubmit: (data: DistrictFormData) => void;
};

export default function DistrictFormPresent({
  title,
  defaultValues,
  onSubmit,
  values,
}: DistrictFormPresentProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<DistrictFormData>({
    values: values ?? defaultValues,
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();

  const onSubmitWrapper = (data: DistrictFormData) => {
    onSubmit(data);
    reset(defaultValues);
    navigate('/adminka/districts/', { replace: true });
  };

  return (
    <>
      <Box>
        <Typography component="h1" variant="h4" sx={{ mb: '2rem', textAlign: 'center' }}>
          {title}
        </Typography>
        <form onSubmit={handleSubmit(onSubmitWrapper)}>
          <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '2rem' }}>
            <Input
              {...register('title')}
              label="Название района"
              placeholder="Введите название района..."
              error={!!errors.title}
              helperText={errors?.title?.message}
            />
            <Input
              {...register('code')}
              label="Код"
              placeholder="Введите код района..."
              error={!!errors.code}
              helperText={errors?.code?.message}
            />
            <Button
              variant="contained"
              type="submit"
              sx={{ width: '200px', mt: '2rem', alignSelf: 'center' }}
            >
              Сохранить
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
}
