import { Box, Container, Typography } from '@mui/material';
import { teal } from '@mui/material/colors';

import { GoalSvg, InternetWorldSvg, RocketSvg, TechSupportSvg, TvHdSvg } from '../svgIcons';
import Feature from './Feature';

const AboutUs = () => {
  return (
    <Container>
      <Typography component="h1" variant="h3" textAlign="center" color={teal[700]}>
        Интернет-провайдер ДАЙМОХК
      </Typography>
      <Typography
        component="h2"
        variant="body1"
        textAlign="center"
        color={teal[500]}
        sx={{ mb: '5rem', mt: '1rem' }}
      >
        У нас есть всё нужное для того, чтобы ваша работа становилась только лучше и эффективнее
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '40px',
          justifyContent: 'center',
        }}
      >
        <Feature
          title="Стабильное соединение для игр и фильмов"
          feature="Для онлайн игр очень важно иметь стабильный пинг и скорость, у нас все это есть."
        >
          {<InternetWorldSvg />}
        </Feature>
        <Feature
          title="Интернет на космических скоростях"
          feature="Безлимитный трафик, одинаково максимальная скорость загрузки и выгрузки данных"
        >
          {<RocketSvg />}
        </Feature>
        <Feature
          title="Техническая поддержка 24/7"
          feature="24-часовая служба технической поддержки. Сердечное отношение к каждому клиенту."
        >
          {<TechSupportSvg />}
        </Feature>
        <Feature
          title="Впечатляющая надежность"
          feature="Современный дата-центр с мощным оборудованием позволяет нам гарантировать надежность связи"
        >
          {<GoalSvg />}
        </Feature>
        <Feature
          title="Телевидение высокой четкости"
          feature="Телевидение высокой четкости — это реальность! Более 140 каналов, 23 из них в HD-качестве"
        >
          {<TvHdSvg />}
        </Feature>
      </Box>
    </Container>
  );
};

export default AboutUs;
