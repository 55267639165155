import { baseAxios as axios } from '../axios/axios';
import { AuthTokens } from '../../storeSlices/authSLice/authStoreSlice';

const enpoint = 'auth/refresh/';

export const refresh = async (refreshToken: string) => {
  axios.defaults.headers.common = { Authorization: `bearer ${refreshToken}` };
  const result = await axios.post<AuthTokens>(enpoint, { refreshToken });
  return result.data;
};
