import { Box, Container, Typography } from '@mui/material';
import { teal } from '@mui/material/colors';

import { ITariff } from '../../storeSlices/tariffSlice/contracts.tariff';
import TariffsContent from './TariffsContent';

type TariffsProps = { wiredTariffs: ITariff[]; wireLessTariffs: ITariff[] };

const Tariffs = ({ wiredTariffs, wireLessTariffs }: TariffsProps) => {
  return (
    <>
      <Box sx={{ backgroundColor: '#ffffff', marginBottom: '10rem' }}>
        <Container>
          <Typography component="h1" variant="h2" textAlign="center" mb="3rem" color={teal[700]}>
            Тарифы на интернет
          </Typography>
          <Box sx={{ mb: '5rem' }}>
            {!!wiredTariffs.length && (
              <TariffsContent tariffs={wiredTariffs} title="Проводные тарифы" />
            )}
          </Box>
          <Box>
            {!!wireLessTariffs.length && (
              <TariffsContent tariffs={wireLessTariffs} title="Беспроводные тарифы" />
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Tariffs;
