import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../store/store.contracts';

import { ITariff, TariffStoreInitialStateData, TariffTypeOption } from './contracts.tariff';
import {
  createTariffThunk,
  deleteTariffThunk,
  fetchWiredTariffsThunk,
  fetchWireLessTariffsThunk,
  updateTariffThunk,
} from './thunks';

import { getMaxPage } from '../utils/getMaxpPage';
import { tariffsPerPage } from '../utils/constants';

export const getEmptyTariff = () => ({
  id: '',
  title: '',
  speed: 0,
  cost: 0,
  type: 'wired',
  status: 'active',
});

export type TariffStoreInitialState = TariffStoreInitialStateData & {
  isLoading: boolean;
};

const tariffStoreInitialState: TariffStoreInitialState = {
  wired: { tariffs: [], maxPage: 0 },
  wireLess: { tariffs: [], maxPage: 0 },
  isLoading: false,
};

const tariffStoreSlice = createSlice({
  name: 'tariff',
  initialState: tariffStoreInitialState,
  reducers: {
    addTariff: (state, { payload }: PayloadAction<ITariff>) => {
      state[payload.type].tariffs = [...state[payload.type].tariffs, payload];
      state[payload.type].maxPage = getMaxPage(state[payload.type].tariffs.length, tariffsPerPage);
    },
    updateTariff: (
      state,
      {
        payload: { previousType, type, id, speed, cost, title, status },
      }: PayloadAction<ITariff & { previousType: TariffTypeOption }>
    ) => {
      if (previousType !== type) {
        state[previousType].tariffs = state[previousType].tariffs.filter(
          (tariff) => tariff.id !== id
        );
        state[previousType].maxPage = getMaxPage(
          state[previousType].tariffs.length,
          tariffsPerPage
        );
        state[type].tariffs = [
          ...state[type].tariffs,
          {
            type,
            cost,
            title,
            speed,
            id,
            status,
          },
        ];
      } else if (previousType === type) {
        state[previousType].tariffs = state[previousType].tariffs.map((tariff) => {
          if (tariff.id !== id) {
            return tariff;
          }
          return { ...tariff, type, speed, cost, title, status };
        });
      }
    },
    removeTariff: (state, { payload: { type, id } }: PayloadAction<ITariff>) => {
      state[type].tariffs = state[type].tariffs.filter((tariff) => tariff.id !== id);
      state[type].maxPage = getMaxPage(state[type].tariffs.length, tariffsPerPage);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWiredTariffsThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchWiredTariffsThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.wired.tariffs = payload;
      state.wired.maxPage = getMaxPage(payload.length, tariffsPerPage);
    });
    builder.addCase(fetchWiredTariffsThunk.rejected, (state, { error }) => {
      state.isLoading = false;

      state.wired.maxPage = 0;
    });
    builder.addCase(fetchWireLessTariffsThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchWireLessTariffsThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.wireLess.tariffs = payload;
      state.wireLess.maxPage = getMaxPage(payload.length, tariffsPerPage);
    });
    builder.addCase(fetchWireLessTariffsThunk.rejected, (state, { error }) => {
      state.isLoading = false;
      state.wireLess.maxPage = 0;
    });
    builder.addCase(createTariffThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createTariffThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (!payload) {
        return;
      }
      state[payload.type].tariffs = [...state[payload.type].tariffs, payload];
      state[payload.type].maxPage = getMaxPage(state[payload.type].tariffs.length, tariffsPerPage);
    });
    builder.addCase(createTariffThunk.rejected, (state, { error }) => {
      state.isLoading = false;
    });
    builder.addCase(updateTariffThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateTariffThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state[payload.type].tariffs = state[payload.type].tariffs.map((tariff) => {
        if (payload.id !== tariff.id) {
          return tariff;
        }
        return { ...tariff, ...payload };
      });
    });
    builder.addCase(updateTariffThunk.rejected, (state, { error }) => {
      state.isLoading = false;
    });
    builder.addCase(deleteTariffThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteTariffThunk.fulfilled, (state, { payload: { key, id } }) => {
      state.isLoading = false;
      state[key].tariffs = state[key].tariffs.filter((tariff) => tariff.id !== id);
      state[key].maxPage = getMaxPage(state[key].tariffs.length, tariffsPerPage);
    });
    builder.addCase(deleteTariffThunk.rejected, (state, { error }) => {
      state.isLoading = false;
    });
  },
});

export const selectedWiredTariffs = (state: RootState) => state.tariffs.wired.tariffs;
export const selectedWireLessTariffs = (state: RootState) => state.tariffs.wireLess.tariffs;
export const selectedTariffs = (state: RootState) => [
  ...state.tariffs.wireLess.tariffs,
  ...state.tariffs.wired.tariffs,
];
export const selectedOneTariffWithoutKey = (id: string) => (state: RootState) => {
  return [...state.tariffs.wired.tariffs, ...state.tariffs.wireLess.tariffs].find(
    (tariff) => tariff.id === id
  );
};

export const selectedOneTariff = (id: string) => (state: RootState) =>
  [...state.tariffs.wireLess.tariffs, ...state.tariffs.wired.tariffs].find(
    (tariff) => tariff.id === id
  );

export const selectedOneTariffByKey = (id: string, key: TariffTypeOption) => (state: RootState) =>
  state.tariffs[key].tariffs.find((tariff) => tariff.id === id);
export const selectedIsTariffLoading = (state: RootState) => state.tariffs.isLoading;
export const selectedWiredMaxPage = (state: RootState) => state.tariffs.wired.maxPage;
export const selectedWireLessMaxPage = (state: RootState) => state.tariffs.wireLess.maxPage;

export const { addTariff, updateTariff, removeTariff } = tariffStoreSlice.actions;
export default tariffStoreSlice.reducer;
