const computeColor = ({
  isError,
  errorColor = '#d32f2f',
  defaultColor = 'black',
}: {
  isError: boolean;
  errorColor?: string;
  defaultColor?: string;
}) => {
  return isError ? errorColor : defaultColor;
};

export default computeColor;
