import { Box, Button, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Input } from '../../../../../components/customInputs/Input';
import schema, { TariffFormData } from '../yupSchema';
import { useNavigate } from 'react-router';
import SelectInput from '../../../../../components/customInputs/SelectInput';

export enum TariffTypeEnum {
  wired = 'wired',
  wireLess = 'wireLess',
}

export default function TariffFormPresent({
  defaultValues,
  title,
  onSubmit,
  values,
}: {
  defaultValues: TariffFormData;
  title: string;
  onSubmit: (data: TariffFormData) => Promise<unknown>;
  values?: TariffFormData;
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm<TariffFormData>({
    defaultValues,
    values: values ?? defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();

  const onSubmitWrapper = (data: TariffFormData) => {
    onSubmit(data).then(() => {
      const loc = data.type === TariffTypeEnum.wired ? '' : '/wireLess';
      reset(defaultValues);
      navigate(`/adminka/tariffs${loc}`, { replace: true });
    });
  };

  return (
    <>
      <Box>
        <Typography component="h1" variant="h4" sx={{ mb: '2rem', textAlign: 'center' }}>
          {title}
        </Typography>
        <form onSubmit={handleSubmit(onSubmitWrapper)}>
          <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '2rem' }}>
            <Input
              {...register('title')}
              label="Название"
              placeholder="Введите название..."
              error={!!errors.title}
              helperText={errors?.title?.message}
            />
            <Input
              {...register('cost')}
              label="Стоимость"
              placeholder="Введите стоимость..."
              error={!!errors.cost}
              helperText={errors?.cost?.message}
            />
            <Input
              {...register('speed')}
              label="Скороть"
              placeholder="Введите скороть..."
              error={!!errors.speed}
              helperText={errors?.speed?.message}
            />
            <SelectInput<TariffFormData>
              control={control}
              name="type"
              title="Тип тарифа"
              label="Выберите тип тарифа"
              options={[
                { value: 'wired', key: 'Проводной тариф' },
                { value: 'wireLess', key: 'Беспроводной тариф' },
              ]}
            />
            <Button
              variant="contained"
              type="submit"
              sx={{ width: '200px', mt: '2rem', alignSelf: 'center' }}
            >
              Сохранить
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
}
