import { createTheme } from '@mui/material';

const theme = createTheme();

theme.typography.body1 = {
  fontSize: '0.82rem',
  '@media (min-width:400px)': {
    fontSize: '1rem',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.3rem',
  },
};
export default theme;
