import { sliceStrBySep } from './sliceStrBySep';

const formatDate = (date?: string) => {
  if (date) {
    let formattedDate = new Date(date).toLocaleString('ru-RU', {
      timeZone: 'UTC',
    });
    formattedDate = sliceStrBySep(formattedDate, ',');
    formattedDate = formattedDate.replaceAll('.', '-');
    return formattedDate + 'г.';
  }
};

export default formatDate;
