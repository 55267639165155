import React from 'react';
import { Theme, ThemeProvider } from '@mui/material';

type WithThemeProps = { theme: Theme; Component: React.ElementType };

const WithTheme = <DataT,>({ theme, Component }: WithThemeProps) => {
  return (props: DataT) => (
    <ThemeProvider theme={theme}>
      <Component {...props} />
    </ThemeProvider>
  );
};

export default WithTheme;
