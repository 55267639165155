import { Outlet } from 'react-router-dom';
import { Footer, ProfileHeader, SideMenu } from '../../../components';
import useBreakpoint from '../../../hooks/useBreakpoint/useBreakpoint';
import { BreakpointsEnum } from '../../../utils/breakpoints';
import { Box, Container } from '@mui/material';
import { profileSideMenuItems } from './constants';

const ProfileLayout = () => {
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === BreakpointsEnum.phone;

  return (
    <>
      <ProfileHeader />
      <Container component="main" className="main" sx={{ display: 'flex', paddingTop: '2rem' }}>
        {!isMobile && (
          <Box component="aside" sx={{ width: '200px', mr: '1.8rem' }} className="profile-aside">
            <SideMenu listItems={profileSideMenuItems} />
          </Box>
        )}
        <Container component="section" className="profile-content">
          <Outlet />
        </Container>
      </Container>
      <Footer />
    </>
  );
};

export default ProfileLayout;
