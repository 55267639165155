import { getDefaultDate } from '../../../utils/getDefaultDate';

export const getOrderFormDefaultValues = ({
  tariffId,
  districtId,
  birthday = new Date().toISOString(),
  passportDate = new Date().toISOString(),
}: {
  tariffId: string;
  districtId: string;
  birthday?: string;
  passportDate?: string;
}) => ({
  address: '',
  addressRegistration: '',
  tariffId: tariffId,
  districtId: districtId,
  gender: 'man',
  familyName: '',
  personalName: '',
  lastName: '',
  passportSeries: '',
  passportNumber: '',
  passportBranch: '',
  passportMainPagePhoto: [],
  passportRegistrationPhoto: [],
  aggreddToShareDate: false,
  phoneNumber: '',
  birthday: getDefaultDate(birthday),
  passportDate: getDefaultDate(passportDate),
});
