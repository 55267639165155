const RocketSvg = () => (
  <svg
    className="feature-svg rocket-svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 239.72 376.36"
  >
    <title>{'feat-icon05'}</title>
    <g data-name="\u0421\u043B\u043E\u0439 2">
      <g data-name="\u0421\u043B\u043E\u0439 1">
        <path
          d="M143.93 26.6H95.77c12.76-15.77 24.09-23.06 24.09-23.06s11.33 7.29 24.07 23.06Zm-7.68 59.82A16.4 16.4 0 1 1 119.86 70a16.4 16.4 0 0 1 16.39 16.4Zm8.39 68a24.79 24.79 0 1 1-24.78-24.8 24.79 24.79 0 0 1 24.78 24.8Zm17.12 68.18H77.94c-36.62-103.1-6.76-165.46 17.83-196h48.16c24.59 30.54 54.46 92.9 17.83 196Zm-6.84 30H84.8l-6.86-30h83.82l-6.84 30Zm-90.26-76.92-31.86 97h49m93.58-96 31.54 96h-49m-73.45-21.5.33 82a45.13 45.13 0 0 0-36.42 18.65 29 29 0 0 0-15.81-4.67 29.26 29.26 0 0 0-29 25.65h232.61a29.27 29.27 0 0 0-29.05-25.65 29 29 0 0 0-15.81 4.67 45.07 45.07 0 0 0-36.4-18.65l.56-83M101.82 40.66s-36.88 67.48-7.68 182m43.74-182s36.9 67.48 7.68 182"
          style={{
            strokeLinejoin: 'round',
            fill: 'none',
            strokeLinecap: 'round',
            strokeWidth: '7.09px',
          }}
        />
        <path
          d="M216.06 320v-42.11M23.64 320v-42.11m32.11 50.59v-35.14M184 328.48v-35.14m-74.3 1.5v-41.42m20.4 41.42v-41.42"
          style={{
            strokeMiterlimit: 22.93,
            fill: 'none',
            strokeLinecap: 'round',
            strokeWidth: '7.09px',
          }}
        />
      </g>
    </g>
  </svg>
);

export default RocketSvg;
