import { getNewsFormDefaultValues } from '../utils';
import { useAppDispatch } from '../../../../../hooks';

import { newsSuccessfullyCreated } from '../../../../../storeSlices/utils/successMessages.constants';
import { useCreateNewsMutation } from '../../../../../storeSlices/newsSlice/query/newsApiSlice';
import { addNews } from '../../../../../storeSlices/newsSlice/newsStoreSlice';
import isImageString from '../../../../../storeSlices/newsSlice/thunks/isBackgroundImageString';
import NewsFormPresent from '../view/NewsFormPresent';
import { useCallback } from 'react';
import useEnqueueMsgWithData from '../../../../../hooks/useEnqueueMsg/useEnqueueMsgWithData';
import { NewsFormData } from '../newsForm.contracts';

export default function NewsFormCreate() {
  const title = 'Создание новости';
  const [createNewsMutation] = useCreateNewsMutation();

  const dispath = useAppDispatch();

  const defaultValues = getNewsFormDefaultValues();

  const createNews = useCallback(
    async (data: NewsFormData) => {
      const newsDto = {
        backgroundImage: data.files[0],
        title: data.title,
        content: data.content,
      };
      const dto = await isImageString(newsDto);
      if (dto) {
        const news = await createNewsMutation(dto).unwrap();
        dispath(addNews(news));
      }
    },
    [createNewsMutation, dispath]
  );

  const onSubmit = useEnqueueMsgWithData({
    fn: createNews,
    msg: newsSuccessfullyCreated,
  });

  return <NewsFormPresent defaultValues={defaultValues} onSubmit={onSubmit} title={title} />;
}
