import { Button, useTheme } from '@mui/material';
import DeleteSvg from '../../svgIcons/DeleteSvg';

const DeleteBtn = ({ onClick }: { onClick: () => void }) => {
  const handleClick = () => {
    onClick();
  };

  const theme = useTheme();

  return (
    <Button variant="outlined" color="error" onClick={handleClick}>
      <DeleteSvg fill={theme.palette.error.main} width="25px" height="25px" />
    </Button>
  );
};

export default DeleteBtn;
