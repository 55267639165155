import * as yup from 'yup';
import { InferType } from 'yup';
import { dateErrorMsg, requiredFieldMessage } from '../../../utils/constants';
import { maxDate, minDate } from '../helpers/constants';

export const orderFormSchemaUpdate = yup.object().shape({
  gender: yup.string().required(requiredFieldMessage),
  phoneNumber: yup.string().required(requiredFieldMessage),
  familyName: yup
    .string()
    .required(requiredFieldMessage)
    .matches(/^[а-яА-ЯёЁ]+$/, 'Кирилица'),
  personalName: yup
    .string()
    .required(requiredFieldMessage)
    .matches(/^[а-яА-ЯёЁ]+$/, 'Кирилица'),
  lastName: yup
    .string()
    .required(requiredFieldMessage)
    .matches(/^[а-яА-ЯёЁ]+$/, 'Кирилица'),
  passportSeries: yup
    .string()
    .required(requiredFieldMessage)
    .matches(/^[0-9]+$/, 'Только цифры')
    .min(4, 'Должно быть четыре символа')
    .max(4, 'Должно быть четыре символа'),
  passportNumber: yup
    .string()
    .required(requiredFieldMessage)
    .matches(/^[0-9]+$/, 'Только цифры')
    .min(6, 'Должно быть шесть символов')
    .max(6, 'Должно быть шесть символов'),
  passportBranch: yup
    .string()
    .required(requiredFieldMessage)
    .matches(/^[а-яА-ЯёЁ0-9_\-., ]+$/, 'Кирилица'),
  addressRegistration: yup
    .string()
    .required(requiredFieldMessage)
    .matches(/^[а-яА-ЯёЁ0-9_\-., ]+$/, 'Кирилица'),
  address: yup
    .string()
    .required(requiredFieldMessage)
    .matches(/^[а-яА-ЯёЁ_\-., ]+[0-9]+$/, 'Кирилица и числа'),
  birthday: yup
    .date()
    .required(requiredFieldMessage)
    .min(minDate, dateErrorMsg)
    .max(maxDate, dateErrorMsg)
    .typeError(dateErrorMsg),
  passportDate: yup
    .date()
    .required(requiredFieldMessage)
    .min(minDate, dateErrorMsg)
    .max(maxDate, dateErrorMsg)
    .typeError(dateErrorMsg),
});

export type OrderFormDataUpdate = InferType<typeof orderFormSchemaUpdate>;
