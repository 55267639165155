export const calculateIndex = ({
  currentPage,
  itemsPerPage,
  index,
}: {
  currentPage: number;
  itemsPerPage: number;
  index: number;
}) => {
  return (currentPage - 1) * itemsPerPage + (index + 1);
};
