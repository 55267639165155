import { Navigate, Outlet } from 'react-router';
import { useAuth } from '../../hooks';

const ForUnloggedUsers = () => {
  const tokens = useAuth();

  return !tokens ? <Outlet /> : <Navigate to="/" replace />;
};

export default ForUnloggedUsers;
