import { useEffect } from 'react';
import { useAppDispatch } from '../useStoreHooks/useStoreHooks';
import { fetchNewsThunk } from '../../storeSlices/newsSlice/thunks';
import {
  fetchWiredTariffsThunk,
  fetchWireLessTariffsThunk,
} from '../../storeSlices/tariffSlice/thunks';
import { checkStorage } from '../../storeSlices/authSLice/authStoreSlice';

const useInitializeStore = () => {
  const dispath = useAppDispatch();
  useEffect(() => {
    dispath(checkStorage());
    dispath(fetchNewsThunk());
    dispath(fetchWiredTariffsThunk());
    dispath(fetchWireLessTariffsThunk());
  }, [dispath]);
};

export default useInitializeStore;
