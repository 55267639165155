import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { Box } from '@mui/system';

type SelectInputOption = {
  key: string;
  value: string;
};

type SelectInputProps<FormData extends FieldValues> = {
  title: string;
  options: SelectInputOption[];
  label: string;
  control: Control<FormData, unknown>;
  name: Path<FormData>;
};

const SelectInput = <FormData extends FieldValues>({
  title,
  options,
  label,
  name,
  control,
}: SelectInputProps<FormData>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: true }}
      render={({ field }) => (
        <Box sx={{ maxWidth: '220px' }}>
          <FormControl fullWidth>
            <InputLabel id="select-label">{title}</InputLabel>
            <Select labelId="select-label" id="select" label={label} {...field}>
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.key}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
    />
  );
};

export default SelectInput;
