import { TariffFormData } from '../yupSchema';
import { formatTariffFormData, getTariffFormDefaultValues } from '../utils';
import { useAppDispatch, useAppSelector } from '../../../../../hooks';

import { useUpdateTariffMutation } from '../../../../../storeSlices/tariffSlice/query/tariffApiSlice';
import {
  selectedIsTariffLoading,
  selectedOneTariffWithoutKey,
  updateTariff,
} from '../../../../../storeSlices/tariffSlice/tariffStoreSlice';
import { useCallback } from 'react';
import useEnqueueMsgWithData from '../../../../../hooks/useEnqueueMsg/useEnqueueMsgWithData';
import TariffFormPresent from '../view/TariffFormPresent';
import { useParams } from 'react-router';
import Spinner from '../../../../../components/spinner';

export default function TariffFormEdit() {
  const { id } = useParams();
  const tariff = useAppSelector(selectedOneTariffWithoutKey(id as string));
  const isLoading = useAppSelector(selectedIsTariffLoading);
  const defaultValues = getTariffFormDefaultValues(tariff);
  const values = getTariffFormDefaultValues(tariff);

  const dispath = useAppDispatch();

  const [updateTariffMutation] = useUpdateTariffMutation();

  const editTariff = useCallback(
    async (data: TariffFormData) => {
      if (id && tariff) {
        const formattedData = formatTariffFormData(data);
        const updatedTariff = await updateTariffMutation({
          ...formattedData,
          id,
        }).unwrap();
        dispath(updateTariff({ ...updatedTariff, previousType: tariff.type }));
      }
    },
    [id, tariff, updateTariffMutation, dispath]
  );

  const onSubmit = useEnqueueMsgWithData<TariffFormData>({
    fn: editTariff,
    msg: 'Тарифф успешно измёнен',
  });

  return (
    <>
      {isLoading ? (
        <Spinner open={true} />
      ) : (
        <TariffFormPresent
          onSubmit={onSubmit}
          defaultValues={defaultValues}
          values={values}
          title="Изменить тарифф"
        />
      )}
    </>
  );
}
