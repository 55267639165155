import { Box, Typography } from '@mui/material';
import { Control, FieldErrors } from 'react-hook-form';

import computeColor from '../../utils/computeColor';
import { FileInput } from '../customInputs/FileInput';
import { OrderFormData } from '../../view/orderPage/helpers/orderFormSchema';

const OrderFormImages = ({
  errors,
  control,
}: {
  errors: FieldErrors<OrderFormData>;
  control: Control<OrderFormData>;
}) => {
  return (
    <>
      <Box>
        <Typography
          sx={{
            mb: '1rem',
            mt: '2rem',
            color: computeColor({ isError: !!errors.passportMainPagePhoto }),
          }}
        >
          Фото главной страницы:
        </Typography>
        <FileInput<OrderFormData>
          name="passportMainPagePhoto"
          control={control}
          error={!!errors.passportMainPagePhoto}
          helperText={errors.passportMainPagePhoto?.message}
        />
      </Box>
      <Box>
        <Typography
          sx={{
            mb: '1rem',
            mt: '2rem',
            color: computeColor({
              isError: !!errors.passportRegistrationPhoto,
            }),
          }}
        >
          Фото страницы регистрации:
        </Typography>
        <FileInput<OrderFormData>
          name="passportRegistrationPhoto"
          control={control}
          error={!!errors.passportRegistrationPhoto}
          helperText={errors.passportRegistrationPhoto?.message}
        />
      </Box>
    </>
  );
};

export default OrderFormImages;
