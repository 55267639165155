import withCarousel from '../carousel';
import Tariff from '../tariff/Tariff';
import tariffCarouselConfig from './tariffCarouselConfig';

import 'swiper/css/pagination';
import './styles.scss';

const TariffCarousel = withCarousel({
  config: tariffCarouselConfig,
  Component: Tariff,
});

export default TariffCarousel;
