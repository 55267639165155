import { FC } from 'react';

import { MobileMenuProps } from '../contracts';
import { WithMobileMenu } from '../withMobileMenu/WithMobileMenu';
import Navigation from '../navigation/Navigation';
import { profileNavigationList } from './constants';

import './profile-mobile-menu.scss';

const ProfileMobileMenu: FC<MobileMenuProps> = ({ isHamburgerOpen, onHamburgerClick }) => {
  return (
    <WithMobileMenu isHamburgerOpen={isHamburgerOpen} onHamburgerClick={onHamburgerClick}>
      <Navigation listItems={profileNavigationList} navListItemClass="mobile-nav__item" />
    </WithMobileMenu>
  );
};

export default ProfileMobileMenu;
