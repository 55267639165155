import { VariantType } from 'notistack';
export type SnackMsg = { message: string; color: VariantType };
const generateMessage = (msg: string, color: VariantType = 'success') => ({
  message: msg,
  color,
});
export const districtSuccessfullyDeleted = generateMessage('Район успешно удален');
export const districtSuccessfullyCreated = generateMessage('Район успешно создан');
export const districtSuccessfullyUpdated = generateMessage('Район успешно обновлен');

export const orderSuccessfullyArchived = generateMessage('Заказ успешно архивирован');
export const orderSuccessfullyCreated = generateMessage('Заказ успешно создан');
export const orderSuccessfullyUpdated = generateMessage('Заказ успешно обновлен');
export const orderSuccessfullyDeleted = generateMessage('Заказ успешно удален');
export const orderSuccessfullyCanseled = generateMessage('Заказ успешно откланен');

export const orderSuccessfullyFetched = generateMessage('Заказы успешно загружены');
export const orderFuiledFetching = generateMessage('Заказы не удалось загрузить.');
