import { useEffect, useState } from 'react';
import ProfilePresent from './ProfilePresent';
import { getProfile } from '../../api/profile/profileApi';
import { IProfile } from '../../api/profile/profile.contracts';
import { useSearchParams } from 'react-router-dom';
import { getErrorMsg } from '../../utils/getErrorMsg';

const ProfileContainer = () => {
  const [profile, setProfile] = useState<IProfile | null>();
  const [searchParams] = useSearchParams();

  const k = searchParams.get('k');
  const s = searchParams.get('s');
  const p = searchParams.get('p');
  const u = searchParams.get('u');

  useEffect(() => {
    (async () => {
      if (k && s && p && u) {
        try {
          const profile = await getProfile({ k, s, p, u });
          setProfile(profile);
        } catch (err) {
          setProfile(null);
          console.error(getErrorMsg(err));
        }
      }
    })();
  }, [k, p, s, u]);

  return <>{!!profile && <ProfilePresent profile={profile} />}</>;
};

export default ProfileContainer;
