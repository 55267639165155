import { Container } from '@mui/material';
import { useSwiper } from 'swiper/react';

const SwiperButtons = () => {
  const swiper = useSwiper();
  return (
    <Container sx={{ maxWidth: 'xs', position: 'relative', top: '-40px' }}>
      <div className="swiper-button-next" onClick={() => swiper.slideNext()}></div>
      <div className="swiper-button-prev" onClick={() => swiper.slidePrev()}></div>
    </Container>
  );
};

export default SwiperButtons;
