import { Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import { Box, Container } from '@mui/system';

import { HeaderLinks } from './HeaderLinks';

import { PhoneSvg } from '../svgIcons';
import withTheme from '../withTheme';
import commonBoxStyles from './commonBoxStyles';
import theme from './headerTheme';

import './header.scss';

const HeaderTop = () => {
  return (
    <div className="header">
      <Container>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            color: '#ffffff',
          }}
        >
          <Box sx={{ ...commonBoxStyles }}>
            <PhoneSvg width="20px" height="20px" fill={green[500]} />
            <Typography
              component="a"
              variant="body1"
              sx={{ ml: '10px' }}
              href="tel:+7928-781-87-88"
            >
              +7928-781-87-88
            </Typography>
          </Box>
          <Box
            sx={{
              ...commonBoxStyles,
            }}
          >
            <HeaderLinks />
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default withTheme({ theme, Component: HeaderTop });
