import { createTheme } from '@mui/material';

const theme = createTheme();

theme.typography.h3 = {
  fontSize: '2.1rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '2.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '3rem',
  },
};

theme.typography.button = {
  fontSize: '1.2rem',

  [theme.breakpoints.up('sm')]: {
    fontSize: '1.5rem',
  },
};

theme.typography.body1 = {
  fontSize: '1rem',

  [theme.breakpoints.up('md')]: {
    fontSize: '1.1rem',
  },
};

export default theme;
