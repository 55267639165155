import { Box, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

import { LoginSvg, UserSvg } from '../svgIcons';
import { useAdmin, useAppDispatch, useAuth } from '../../hooks';
import { resetTokens } from '../../storeSlices/authSLice/authStoreSlice';
import config from '../../config/config';

export const HeaderLinks = () => {
  const admin = useAdmin();
  const isAuth = useAuth();
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  return (
    <>
      {!isAuth && (
        <Box sx={{ display: 'flex', alignItems: 'center' }} onClick={() => navigate('/login')}>
          <Typography component="p" variant="body1" sx={{ mr: '10px' }}>
            Войти
          </Typography>
          <LoginSvg />
        </Box>
      )}
      {admin && (
        <Typography component="p" variant="body1" sx={{ mr: '20px' }}>
          <Link to="/adminka/news">ADMINKA</Link>
        </Typography>
      )}
      {!admin && isAuth && (
        <>
          <Typography component="p" variant="body1" sx={{ mr: '10px' }}>
            <Link to={config.lkLink}>ЛИЧНЫЙ КАБИНЕТ</Link>
          </Typography>
          <UserSvg width="20px" height="20px" />
        </>
      )}
      {isAuth && (
        <>
          <Typography
            onClick={() => dispatch(resetTokens())}
            component="p"
            variant="body1"
            sx={{ ml: '10px' }}
          >
            ВЫЙТИ
          </Typography>
        </>
      )}
    </>
  );
};
