import { createAsyncThunk } from '@reduxjs/toolkit';
import { createNews } from '../../../api/news/newsApi';
import { CreateNewsDto } from '../../../view/adminka/news/main/contracts.news';
import isImageString from './isBackgroundImageString';

const createNewsThunk = createAsyncThunk(
  'news/createNewsThunk',
  async (createNewsDto: CreateNewsDto) => {
    const data = await isImageString(createNewsDto);
    if (data) {
      const news = await createNews(data);
      return news;
    }
  }
);

export default createNewsThunk;
