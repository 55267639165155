import { Box, Button, Paper, Typography } from '@mui/material';
import { green } from '@mui/material/colors';

import { ITariff } from '../../storeSlices/tariffSlice/contracts.tariff';
import tariffTheme from './tariffTheme';
import withTheme from '../withTheme';

import './circle.scss';
import './styles.scss';
import { useNavigate } from 'react-router';

const Tariff = (tariff: ITariff) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#ffffff',
        padding: '1.5rem',
        borderRadius: '10px',
        border: `${green[400]} 2px solid`,
      }}
    >
      <Paper
        variant="elevation"
        className="tariff-box"
        sx={{
          mb: '1rem',
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: green[300],
        }}
      >
        <Typography component="p" color="#ffffff" variant="h5">
          до {tariff.speed} Mbps
        </Typography>
      </Paper>
      <Typography component="h1" variant="h4" mb="1rem">
        {tariff.title}
      </Typography>
      <Typography component="p" variant="h4" mb="1rem" color={green[400]}>
        {tariff.cost} &#8381; / мес.
      </Typography>
      <Button
        variant="contained"
        className="tariff-box"
        sx={{ color: '#ffffff', mt: '1rem' }}
        onClick={() => navigate(`order/create/${tariff.id}`)}
      >
        <Typography component="p" variant="h5">
          Подключить
        </Typography>
      </Button>
    </Box>
  );
};

export default withTheme({ theme: tariffTheme, Component: Tariff });
