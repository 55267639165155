const TVSvg = () => (
  <svg
    className="nav-svg tv-svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 508 508"
    xmlSpace="preserve"
  >
    <path d="M465.75 111.7h-273l61.2-83.2c4.6-6.3 3.3-15.1-3-19.7s-15.1-3.3-19.7 3l-65 88.2-53.7-93c-3.9-6.7-12.5-9-19.3-5.1-6.7 3.9-9.1 12.5-5.2 19.3l52.2 90.6h-98c-23.2-.1-42.2 18.9-42.2 42.3v311.6c0 23.3 19 42.3 42.3 42.3h423.3c23.3 0 42.3-19 42.3-42.3V154.1c.1-23.4-18.9-42.4-42.2-42.4zm-437.5 42.4c0-7.8 6.4-14.2 14.1-14.2h423.3c7.8 0 14.1 6.3 14.1 14.1v255.1h-28.2V181.8c0-7.8-6.3-14.1-14.1-14.1H70.65c-7.8 0-14.1 6.3-14.1 14.1v227.3h-28.3v-255zm395.2 41.8v213.3H84.75V195.9h338.7zm56.4 269.8c0 7.7-6.4 14.1-14.1 14.1h-32.6v-5.4c0-7.8-6.3-14.1-14.1-14.1s-14.1 6.3-14.1 14.1v5.4h-30.9v-5.4c0-7.8-6.3-14.1-14.1-14.1s-14.1 6.3-14.1 14.1v5.4H42.35c-7.8 0-14.1-6.3-14.1-14.1v-28.3h451.6v28.3z" />
  </svg>
);

export default TVSvg;
