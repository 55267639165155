import { apiSlice } from '../../../api/apiSlice/apiSlice';
import { LoginDto } from '../../../api/auth/login';
import { AuthTokens } from '../../authSLice/authStoreSlice';

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<AuthTokens, LoginDto>({
      query: (loginDto) => ({
        url: 'auth/login',
        method: 'POST',
        body: { ...loginDto },
      }),
    }),
  }),
});

export const { useLoginMutation } = authApiSlice;
