import { Box, Typography } from '@mui/material';

import { OrderItem } from './OrderItem';
import { IOrder } from '../../../api/order/orderApi';
import { IDistrcit } from '../../../api/district/districtApi';
import { ITariff } from '../../../storeSlices/tariffSlice/contracts.tariff';
import formatDate from '../../../utils/formatDate';
import { getGenderInRussian } from '../../../utils/getGenderInRussian';

type OrderPresentProps = {
  order: IOrder;
  district: IDistrcit;
  tariff: ITariff;
};

const OrderPresent = ({ order, district, tariff }: OrderPresentProps) => {
  return (
    <>
      <Typography component="h1" variant="h3" textAlign="center" sx={{ mb: '2rem' }}>
        Анкета заказчика
      </Typography>
      <Box
        sx={{
          maxWidth: '600px',
          rowGap: '.8rem',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <OrderItem
          title="ФИО"
          value={`${order?.familyName} ${order.personalName} ${order.lastName}`}
        />
        <OrderItem title="Код района" value={district.code} />
        <OrderItem title="Адрес" value={`${district.title} ${order.address}`} />
        <OrderItem title="Тариф" value={tariff.title} />
        <OrderItem title="Номер телефона" value={order.phoneNumber} />
        <OrderItem title="Пол" value={getGenderInRussian(order.gender)} />
        <OrderItem title="Адресс регистрации" value={order.addressRegistration} />
        <OrderItem
          title="Серия и номер пасспорта"
          value={`${order?.passportSeries} ${order.passportNumber}`}
        />
        <OrderItem title="Где выдан пасспорт" value={order.passportBranch} />
        <OrderItem title="Дата рождения" value={formatDate(order.birthday)} />
        <OrderItem title="Когда выдан пасспорт" value={formatDate(order.passportDate)} />
        <img src={order.passportMainPagePhoto} alt="Фото главной страницы пасспорта" />
        <img src={order.passportRegistrationPhoto} alt="Фото страницы регистрации пасспорта" />
      </Box>
    </>
  );
};

export default OrderPresent;
