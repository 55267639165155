import { Button, Container, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const E404 = () => {
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '70vh',
      }}
    >
      <Typography component="h1" variant="h3" color="#000000">
        У вас нет доступы к этой странице
      </Typography>

      <Button variant="outlined" sx={{ mt: '2rem' }}>
        <Link to="/">На главную страницу</Link>
      </Button>
    </Container>
  );
};

export default E404;
